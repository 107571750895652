import { CommonModule } from '@angular/common';
import { Component, Input, input, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserActionsComponent } from "../../../../shared/components/user-actions/user-actions.component";
import { UserService } from 'src/app/core/services/user.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-user-side-bar',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterLink, AppButtonComponent, UserActionsComponent],
  templateUrl: './user-side-bar.component.html',
  styleUrl: './user-side-bar.component.scss'
})
export class UserSideBarComponent {

  isLoggedIn = input<boolean>();
  userInfo = input.required<UserModel>();
  menuItems = input<Array<any>>();
  selectedMenuItem = model.required<string>();

  @Input() inputStyles: any = null;
  @Input() logoStyles: any = null;
  @Input() buttonStyles: any = null;
  @Input() activeSections: any = null;

  userBlocked = input<boolean>();

  userInfoMock = {
    rank: '1º',
    score: '1000',
  }

  buttonGonnaBePartnerOptions = {
    primaryColor: '',
    buttonTextColor: '',
    buttonBorderWidth: '1px',
    buttonBorderColor: '',
    buttonText: 'Quero ser sócio',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 w-100 btn-primary',
    fontSize: 12
  };

  buttonLoginOptions = {
    primaryColor: '',
    buttonTextColor: '',
    buttonBorderWidth: '1px',
    buttonBorderColor: '',
    buttonText: 'Entrar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 w-100',
    fontSize: 12
  };
  styles: { [key: string]: string } = {};

  actionsSideColor: any = ''
  menuStyles: { [key: string]: string } = {};
  userRank: string = '';
  userScore: string = '';
  userPhoto: string = '';

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const arrayOfStyles = Object.keys(this.inputStyles).map((key) => ({
      style: key,
      value: this.inputStyles[key],
    }));
    let styleButtonColor = arrayOfStyles.find(item => item.style === '--button-color');
    let styleButtonTextColor = arrayOfStyles.find(item => item.style === '--button-color-text');
    let styleButtonBorderColor = arrayOfStyles.find(item => item.style === '--button-border');
    let menuColorItem = arrayOfStyles.find(item => item.style === '--menu-color-item');
    this.buttonGonnaBePartnerOptions.primaryColor = styleButtonColor?.value;
    this.buttonGonnaBePartnerOptions.buttonTextColor = styleButtonTextColor?.value;
    this.buttonGonnaBePartnerOptions.buttonBorderColor = styleButtonBorderColor?.value;

    let enterStyleButtonColor = arrayOfStyles.find(item => item.style === '--button-color-enter');
    let enterStyleButtonTextColor = arrayOfStyles.find(item => item.style === '--button-color-text-enter');
    let enterStyleButtonBorderColor = arrayOfStyles.find(item => item.style === '--button-border-enter');
    this.buttonLoginOptions.primaryColor = enterStyleButtonColor?.value;
    this.buttonLoginOptions.buttonTextColor = enterStyleButtonTextColor?.value;
    this.buttonLoginOptions.buttonBorderColor = enterStyleButtonBorderColor?.value;

    this.actionsSideColor = menuColorItem?.value;
    this.getRanking();
  }



  closeOffcanvas(): void {
    const closeButton = document.getElementById('btn-offcanvas-close') as HTMLButtonElement;

    // Check if the button exists before trying to click it
    if (closeButton) {
      closeButton.click();
    }
  }

  navigationHeaderItems(menuItemId: string): void {
    if (this.isLoggedIn() && this.userBlocked()) {
      return;
    }

    this.selectedMenuItem.set(menuItemId);

    this.closeOffcanvas();
  }

  getRanking() {
    const user = this.userInfo();

    if (user?.id && this.authService.hasToken()) {
      this.userService.getMemberFinalScoreByMemberId(user.id).subscribe({
        next: (response) => {
          this.userRank = response?.currentPosition;
          // this.userScore = response?.totalPoints;
          this.userPhoto = response?.photo ?? 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745';

        },
        error: (err) => {
          console.error('Error fetching user details', err);
        }
      });
    }
  }

}
