import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './core/services/auth.service';
import { UserModel } from './shared/models/userModel';
import { SpinnerService } from './core/services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { AdminService } from './core/services/admin.service';
import { GeneralConfigModel } from './shared/models/generalConfigModel';
import { MemberService } from './core/services/member.service';
import { FileService } from './core/services/file.service';
import { FaviconService } from './shared/services/favicon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'sociotorcedor-clube-web';
  styles: any;
  bgColor = '';
  isLoggedIn = false;
  userInfo: UserModel;
  isLoading$ = this.spinnerService.isLoading$;
  generalConfig: GeneralConfigModel;

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private memberService: MemberService,
    private fileService: FileService,
    private faviconService: FaviconService
  ) {

    this.setMatIcon();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit(): void {
    this.getGeneralConfig();
  }

  ngAfterContentChecked(): void {
    this.authService.isLoggedIn.subscribe(status => {
      this.isLoggedIn = status;
    });
  }

  getGeneralConfig() {

    this.memberService.getGeneralConfig().subscribe((res: any) => {
      this.generalConfig = res.content[0];
      this.setCssStyles();
      let title = document.getElementById('siteTitle');
      if (title) {
        title.innerText = this.generalConfig.pageTitle;
      }
      localStorage.setItem('enableLoggedArea', JSON.stringify(this.generalConfig.enableLoggedArea));
      localStorage.setItem('enableNewMembership', JSON.stringify(this.generalConfig.enableNewMembership));
      localStorage.setItem('enablePortal', JSON.stringify(this.generalConfig.enablePortal));
      localStorage.setItem('socialNetworks', JSON.stringify(this.generalConfig.socialNetworks));
      localStorage.setItem('primaryColor', this.generalConfig.primaryColor);
      localStorage.setItem('secondaryColor', this.generalConfig.secondaryColor);
      localStorage.setItem('imageLogo', this.fileService.getDocDownloadUrl(this.generalConfig.imageLogo.id as string));
      localStorage.setItem('clubName', this.generalConfig.name as string);
      localStorage.setItem('clubFirstName', this.extractFirstName(this.generalConfig.name) as string);
      localStorage.setItem('monthMaximumNumberInstallment', this.generalConfig.monthMaximumNumberInstallment as string);
      localStorage.setItem('quarterlyMaximumNumberInstallment', this.generalConfig.quarterlyMaximumNumberInstallment as string);
      localStorage.setItem('semiAnnualMaximumNumberInstallment', this.generalConfig.semiAnnualMaximumNumberInstallment as string);
      localStorage.setItem('yearMaximumNumberInstallment', this.generalConfig.yearMaximumNumberInstallment as string);
      localStorage.setItem('seasonMaximumNumberInstallment', this.generalConfig.seasonMaximumNumberInstallment as string);
      localStorage.setItem('enableWhatsappButton', JSON.stringify(this.generalConfig.enableWhatsappButton));
      localStorage.setItem('whatsappLink', this.generalConfig.whatsappLink || '');
      localStorage.setItem('enableRenewalButton', JSON.stringify(this.generalConfig.enableRenewalButton || ''));
      localStorage.setItem('enableUpgradeable', JSON.stringify(this.generalConfig.enableUpgradeable || ''));
      localStorage.setItem('enableAnticipationRenewal', JSON.stringify(this.generalConfig.enableAnticipationRenewal || ''));
      localStorage.setItem('hexadecimalCard', this.generalConfig.hexadecimalCard.toString());
      localStorage.setItem('virtualCard', JSON.stringify(this.generalConfig.virtualCard));
      localStorage.setItem('showContinuousMonth', JSON.stringify(this.generalConfig.showContinuousMonth));

      let buttonPrimary = {
        color: this.generalConfig.primaryColorButton,
        textColor: this.generalConfig.primaryTextColorButton,
        borderColor: this.generalConfig.primaryColorBorderButton,
        hasBorder: this.generalConfig.hasPrimaryBorder
      }
      localStorage.setItem('buttonPrimary', JSON.stringify(buttonPrimary));
      let buttonSecondary = {
        color: this.generalConfig.secondaryColorButton,
        textColor: this.generalConfig.secondaryTexColorButton,
        borderColor: this.generalConfig.secondaryColorBorderButton,
        hasBorder: this.generalConfig.hasSecondaryBorder
      }
      localStorage.setItem('buttonSecondary', JSON.stringify(buttonSecondary));

      localStorage.setItem('clubCnpj', this.generalConfig?.cnpj);

      if (this.generalConfig.menuEveryoneCard) {
        localStorage.setItem('labelEveryoneCard', this.generalConfig.labelEveryoneCard);
        localStorage.setItem('menuEveryoneCard', JSON.stringify(this.generalConfig.menuEveryoneCard));
      } else {
        localStorage.removeItem('labelEveryoneCard');
        localStorage.removeItem('menuEveryoneCard');
      }

      if (this.generalConfig.messageLoggedArea) {
        localStorage.setItem('messageLoggedArea', this.generalConfig.messageLoggedArea);
      };
      if (this.generalConfig.messageNewMembership) {
        localStorage.setItem('messageNewMembership', this.generalConfig.messageNewMembership);
      };
      if (this.generalConfig.messagePortal) {
        localStorage.setItem('messagePortal', this.generalConfig.messagePortal);
      }

      localStorage.setItem('hasLoginMessage', JSON.stringify('false'));

      if (this.generalConfig.hasLoginMessage) {
        localStorage.setItem('hasLoginMessage', JSON.stringify(this.generalConfig.hasLoginMessage));
      }

      if (this.generalConfig.loginMessageTitle) {
        localStorage.setItem('loginMessageTitle', this.generalConfig.loginMessageTitle);
      }
      if (this.generalConfig.loginMessage) {
        localStorage.setItem('loginMessage', this.generalConfig.loginMessage);
      }
      this.faviconService.setFavicon(localStorage.getItem('imageLogo') || 'favicon.ico');
    });

  }

  setMatIcon(): void {
    this.matIconRegistry.addSvgIcon("american-express", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/american-express.svg"));
    this.matIconRegistry.addSvgIcon("diners-club", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/diners-club.svg"));
    this.matIconRegistry.addSvgIcon("discover", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/discover.svg"));
    this.matIconRegistry.addSvgIcon("hipercard", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/hipercard.svg"));
    this.matIconRegistry.addSvgIcon("elo", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/elo.svg"));
    this.matIconRegistry.addSvgIcon("master", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/master.svg"));
    this.matIconRegistry.addSvgIcon("visa", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/visa.svg"));
    this.matIconRegistry.addSvgIcon("pix-white", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/pix-white.svg"));
    this.matIconRegistry.addSvgIcon("pix-darker", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/pix-darker.svg"));
    this.matIconRegistry.addSvgIcon("pix-green", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/pix-green.svg"));
    this.matIconRegistry.addSvgIcon("up_arrow", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/arrowUp.svg"));
    this.matIconRegistry.addSvgIcon("instagram", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/instagram.svg"));
    this.matIconRegistry.addSvgIcon("twitter", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/twitter.svg"));
    this.matIconRegistry.addSvgIcon("youtube", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/youtube.svg"));
    this.matIconRegistry.addSvgIcon("whatsapp", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/whatsapp.svg"));
    this.matIconRegistry.addSvgIcon("t-shirt", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/t-shirt.svg"));
    this.matIconRegistry.addSvgIcon("ball-ex-card", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/ball-ex-card.svg"));
    this.matIconRegistry.addSvgIcon("guy-ex-card", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/guy-ex-card.svg"));
    this.matIconRegistry.addSvgIcon("tshirt-ex-card", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/tshirt-ex-card.svg"));
    this.matIconRegistry.addSvgIcon("outback", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/outback.svg"));
    this.matIconRegistry.addSvgIcon("ze-delivery", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/ze-delivery.svg"));
    this.matIconRegistry.addSvgIcon("envelope", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/envelope-escuro.svg"));
    this.matIconRegistry.addSvgIcon("share", this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/share-escuro.svg"));
  }



  extractFirstName(name: string | undefined): string | null {
    if (!name) return null;

    // Retorna a primeira palavra do nome
    return name.split(' ')[0];
  }

  setCssStyles() {
    this.styles = {
      '--primary-color': this.generalConfig.primaryColor,
      '--secondary-color': this.generalConfig.secondaryColor,
      '--background-color': this.generalConfig.backgroundColor,
    }
  }

}
