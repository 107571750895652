
<div class="card d-grid card-default"
    [ngClass]="{ 'card-special': carouselCardData().highlight, 'active': isMobile() && indexJ() === 0, 'col-4': chunkCarouselSize() === 3 }">
    <div class="header-v2 align-items-center d-flex header-v2 justify-content-center">
        <img [src]="carouselCardData().src" class="img-fluid" alt="...">
        <div class="info" *ngIf="templateVersion() === 2">
            <h5 class="card-title">
                {{ carouselCardData().title }}
            </h5>
            <p class="periodo-v2">{{ carouselCardData().periodo }}
            </p>
            <h1 class="valor-v2">{{ carouselCardData().valor }}
            </h1>
        </div>
    </div>
    <div class="card-title p-1">
        {{ carouselCardData().title }}
    </div>

    <div class="card-body">
        @if (carouselCardData().enableItems.length) {
            <div class="overflow-auto custom-scrollbar mh-100">
                <ul class="card-list card-description">
                    <li class="enable" *ngFor="let enableItem of carouselCardData().enableItems">
                        <i class="bi bi-check-circle-fill"></i>{{ enableItem }}
                    </li>
                </ul>
            </div>
        }
    </div>

    <div class="card-bottom">
        <span class="align-items-center d-flex justify-content-center">A partir de</span>
        <div class="valor pb-2 card-price">{{ carouselCardData().valor }}</div>
        <app-button class="app-button" [style]="styles()"
            [buttonOptions]="carouselCardData().buttonOptions" (buttonClick)="buttonAction(carouselCardData())">
        </app-button>
    </div>
</div>
