import { Component, Input, OnChanges, Provider, SimpleChanges, forwardRef, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';

const COUNTRY_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppInputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ValidationErrorsComponent],
  providers: [COUNTRY_CONTROL_VALUE_ACCESSOR],
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss']
})

export class AppInputComponent<T> extends ControlValueAccessorDirective<T> implements OnChanges {
  @Input() id = 'inputId';
  @Input() label = 'Label';
  @Input() placeholder = '';
  @Input() type = InputType.TEXT;
  maxLength = input<number>(200);
  minLength = input<number>(0);
  isUpperCase = input<boolean>(false);

  customErrorMessages: Record<string, string> = {};
  InputType = InputType;

  // TODO: Rever estrutura de color
  labelColor = 'white';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.defineErrorMessage();
    }
  }

  setMaxLengthNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length > this.maxLength()) {
      inputElement.value = inputElement.value.slice(0, this.maxLength());
    }
  }

  transformInput(event: Event) {    
    if ((this.type === InputType.TEXT || this.type === InputType.NAME) && this.isUpperCase()) {
      const inputElement = event.target as HTMLInputElement;
      inputElement.value = inputElement.value.toUpperCase();
    }
    if(this.type === InputType.DATE){
      const inputElement = event.target as HTMLInputElement; 
      //esse if remove as possíveis tentativas de uma data diferente do padrão.
      if(inputElement.value.length > 10) {
        inputElement.value = inputElement.value.slice(0, 4) + inputElement.value.slice(4 + 1)
      }
    }
    if (this.type === InputType.EMAIL) {
      const inputElement = event.target as HTMLInputElement;
      inputElement.value = inputElement.value.toLowerCase();
    }
  }

  defineErrorMessage(): void {
    switch (this.type) {
      case InputType.EMAIL:
        this.customErrorMessages = { email: 'Email inválido' }
        break;
      case InputType.PASSWORD:
        this.customErrorMessages = { passwordMismatch: 'As senhas não são correspondentes' }
        break;
      case InputType.DATE:
        this.customErrorMessages = { invalidDate: 'Data inválida' }
        break;
      case InputType.NAME:
        this.customErrorMessages = { pattern: 'Informar nome completo' }
        break;
      default:
        break;
    }
  }

}

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  DATE = 'date',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  NAME = 'name',
}

