export interface PaymentInfoModel {
  plan: string;
  frequency: string;
  invoiceRecurrence: boolean;
  formOfPayment: string;
  creditCard?: string;
  numberOfInstallments?: string;
  dependents?: string;
}

export interface CreditCardInfoModel {
  cardNumber: string;
  expirationDate: string;
  cpf: string;
  cvv: string;
  nameCard: string;
  cardOperator: string;
  cardIcon?: string;
}

export interface PaymentSummaryItem {
  planItemDescription: string;
  planItemlPrice: number;
  planItemDetail: string;
}

export enum PaymentType {
  PIX = 'PIX',
  CREDITO = 'Cartão de Crédito',
  CREDITO_ABREVIADO = 'C. de Crédito',
  CREDITO_RECORRENCIA = 'Crédito recorrência',
  BOLETO = 'boleto',
  GRATUITO = 'Gratuito',
}

export enum PaymentTypeId {
  PIX = 'e4359fb3-9fb7-403c-bee6-47a27516085b',
  CREDITO = '3f316202-7b6b-4890-b5ad-3b1a613bfd6a',
  CREDITO_ABREVIADO = '3f316202-7b6b-4890-b5ad-3b1a613bfd6a',
  CREDITO_RECORRENCIA = '50d719c9-7442-4766-82d7-fdbd55f7235b',
  BOLETO = '784e48b2-8c73-4b3c-9844-3490dcbc6151',
  GRATUITO = 'ea737f0c-441b-46f6-a7d4-b139f8b16357',
  ISENTO = '784e48b2-8c73-4b3c-9844-3490dcbc6151',
}

export enum PaymentFrequency {
  MENSAL = 'mensal',
  TRIMESTRAL = 'trimestral',
  SEMESTRAL = 'semestral',
  ANUAL = 'anual',
  TEMPORADA = 'temporada',
}

export enum PaymentFrequencyId {
  MENSAL = '0cebe0c2-22ee-483d-90cb-9b9f52c2ca3b',
  TRIMESTRAL = 'b4b356fa-0482-4cba-8ba5-bb3d687e6c85',
  SEMESTRAL = '09f61bac-9501-466d-b98d-d2fd533c6be5',
  ANUAL = 'a407d1b9-5bc7-4383-83e4-8c2f822a3f52',
  TEMPORADA = '10f61bac-9501-466d-b98d-d2fd533c6be3',
}
