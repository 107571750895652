import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CardsResgatar } from 'src/app/shared/models/cardsResgatar';
import { CardsPalpites } from 'src/app/shared/models/cardsPalpites';
import { AppCardsResgatarComponent } from 'src/app/shared/components/app-cards-resgatar/app-cards-resgatar.component';
import { AppCardsPalpitesComponent } from 'src/app/shared/components/app-cards-palpites/app-cards-palpites.component';
import { GuessModalComponent } from 'src/app/shared/components/guess-modal/guess-modal.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { ScoreGuessService } from 'src/app/core/services/score-guess.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { GameService } from 'src/app/core/services/games.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ExperienceService } from 'src/app/core/services/experience.service';
import { MemberService } from 'src/app/core/services/member.service';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-experiencias',
  standalone: true,
  imports: [
    CommonModule,
    AppCardsResgatarComponent,
    AppCardsPalpitesComponent,
    GuessModalComponent,
    AppButtonComponent,
  ],
  templateUrl: './experiencias.component.html',
  styleUrl: './experiencias.component.scss',
})
export class ExperienciasComponent implements OnInit {
  labelColor = 'white';
  itemsFiltered: any;

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  items!: CardsResgatar[];

  matchModalData: any[];

  itemsPalpites = [
    {
      teams: [
        {
          logo: '/assets/teams/football-fc-grande.png',
          nome: 'Football FC',
        },
        {
          logo: '/assets/teams/estd-grande.png',
          nome: 'ESTD',
        },
      ],
      points: '400',
      prediction: null,
      result: null,
      winner: false,
    },
    {
      teams: [
        {
          logo: '/assets/teams/football-fc-grande.png',
          nome: 'Football FC',
        },
        {
          logo: '/assets/teams/estd-grande.png',
          nome: 'ESTD',
        },
      ],
      points: '400',
      prediction: {
        firstTeam: '2',
        secondTeam: '0',
      },
      result: null,
      winner: false,
    },
    {
      teams: [
        {
          logo: '/assets/teams/football-fc-grande.png',
          nome: 'Football FC',
        },
        {
          logo: '/assets/teams/estd-grande.png',
          nome: 'ESTD',
        },
      ],
      points: '400',
      prediction: {
        firstTeam: '2',
        secondTeam: '0',
      },
      result: {
        firstTeam: '3',
        secondTeam: '1',
      },
      winner: false,
    },
    {
      teams: [
        {
          logo: '/assets/teams/football-fc-grande.png',
          nome: 'Football FC',
        },
        {
          logo: '/assets/teams/estd-grande.png',
          nome: 'ESTD',
        },
      ],
      points: '400',
      prediction: {
        firstTeam: '2',
        secondTeam: '0',
      },
      result: {
        firstTeam: '2',
        secondTeam: '0',
      },
      winner: true,
    },
  ] as CardsPalpites[];

  itemsExperienciasResgatadas = [] as CardsResgatar[];

  gameList: any[] = [];
  logoUrl: string;
  pastGuesses: any[] = [];
  score: any;

  userInfo: UserModel;
  currentPlan!: any;

  constructor(
    private modalService: ModalService,
    private location: Location,
    private scoreGuessService: ScoreGuessService,
    private userService: UserService,
    private gameService: GameService,
    private commonService: CommonService,
    private experienceService: ExperienceService,
    private memberService: MemberService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.userService.currentUser.subscribe((user) => {
      this.userInfo = user as UserModel;
      this.experienceService.getMemberScore(user?.id).subscribe((score) => {
        this.score = score;
      });
      this.experienceService
        .getRescuesExperiences(this.userInfo.id)
        .subscribe((rescues) => {
          this.itemsExperienciasResgatadas =
            this.setValuesExperiencesRescues(rescues);
        });
      this.memberService
        .getMemberPlanDashboardData(this.userInfo.id)
        .subscribe({
          next: (currentPlan: any) => {
            this.currentPlan = currentPlan;
            this.experienceService
              .getExperiencesByPlan(currentPlan.plan.id)
              .subscribe((res: any) => {
                this.items = this.setValuesExperiencesFiltered(res);
              });
          },
          error: (e) => {
            console.error('Error:', e);
            this.alertService.showAlert(AlertType.WARNING, e.error.message);
          },
        });
    });
  }

  setValuesExperiencesRescues(experiences: any): CardsResgatar[] {
    let returnExperiences: CardsResgatar[] = [];
    experiences.forEach((item: any) => {
      let experience;
      experience = {
        rescue: false,
        topIcon: true,
        title: item.experiences.name,
        content: item.experiences.description,
        image: this.commonService.getImageStr(item.experiences.document.id),
        valid: item.experiences.isActive,
        point: item.experiences.requiredPoints,
        value: item.experiences.value,
        inactiveItem: false,
        memberId: this.userInfo.id,
        inactiveButton: false,
        id: item.id,
      } as CardsResgatar;
      returnExperiences.push(experience);
    });
    return returnExperiences;
  }

  /* setValuesExperiences(experiencias:any): CardsResgatar[] */
  setValuesExperiencesFiltered(experiences: any): CardsResgatar[] {
    let returnExperiences: CardsResgatar[] = [];
    experiences.forEach((item: any) => {
      let experience;
      experience = {
        rescue: true,
        topIcon: true,
        title: item.name,
        content: item.description,
        image: this.commonService.getImageStr(item.document.id),
        valid: item.isActive,
        point: item.requiredPoints,
        value: item.value,
        inactiveItem: false,
        memberId: this.userInfo.id,
        inactiveButton: false,
        id: item.id,
      } as CardsResgatar;
      returnExperiences.push(experience);
    });
    return returnExperiences;

    //return experiences
  }

  ngOnInit() {
    this.getLogo();
    this.validateAccess();
    this.getAllGames();
  }

  getAllGames() {
    this.gameService.getAllGames().subscribe((data) => {
      this.gameList = data.content
        .sort((a, b) => {
          const dateA = new Date(`${a.gameDate}T${a.gameTime}`);
          const dateB = new Date(`${b.gameDate}T${b.gameTime}`);
          return dateB.getTime() - dateA.getTime();
        })
        .map((game) => {
          return {
            id: game.id,
            teams: [
              {
                logo: localStorage.getItem('imageLogo'),
                nome: game.soccerTeamName,
              },
              {
                logo: this.commonService.getImageStr(
                  game.adversary.document.id
                ),
                nome: game.adversary.name,
              },
            ],
            points: game.pointsPerHit,
            prediction: null,
            result: {
              firstTeam: game.soccerTeamScore,
              secondTeam: game.adversaryScore,
            },
            winner: false,
            date: new Date(`${game.gameDate}T${game.gameTime}`)
              .toLocaleString('pt-BR')
              .replace(', ', ' às '),
            canGuess:
              new Date(`${game.gameDate}T${game.gameTime}`) >
              new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
            championship: game.championship.title,
          };
        });
      this.getScoreGuessByMemberId();
    });
  }

  getScoreGuessByMemberId() {
    this.scoreGuessService
      .getScoreGuessByMemberId(this.userInfo.id)
      .subscribe((data) => {
        this.pastGuesses = data.pastGuesses;

        if (this.pastGuesses) {
          this.guessMatch();
        }
      });
  }

  guessMatch() {
    this.gameList.forEach((game) => {
      this.pastGuesses.forEach((guess) => {
        if (game.id === guess.game.id) {
          game.prediction = {
            firstTeam: guess.soccerTeamScore,
            secondTeam: guess.adversaryScore,
          };
          if (
            game.prediction.firstTeam === game.result.firstTeam &&
            game.prediction.secondTeam === game.result.secondTeam
          ) {
            game.winner = true;
          }
        }
      });
    });
  }

  getLogo() {
    this.gameService.getNextMatches().subscribe({
      next: (response: any) => {
        let nextMatchesResponse = response;
        this.logoUrl = this.commonService.getImageStr(
          nextMatchesResponse.logo.id
        );
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }

  openModalGuess(matchData: any): void {
    this.matchModalData = matchData;
    this.modalService.open('modal-guess');
  }

  // Método de validação de acesso
  private validateAccess(): void {
    const activeSections = localStorage.getItem('activeSections');

    if (!activeSections) {
      this.handleAccessDenied();
      return;
    }

    const parsedSections = JSON.parse(activeSections);

    if (!parsedSections['experiencias']) {
      this.handleAccessDenied();
    }
  }

  // Tratamento de acesso negado
  private handleAccessDenied(): void {
    this.alertService.showAlert(
      AlertType.WARNING,
      'Você não possui acesso a essa página'
    );
    this.router.navigate(['/dashboard']);
  }

  back() {
    this.location.back();
  }

  onModalConfirm(event: any): void {
    let guess = {
      memberId: this.userInfo.id,
      game: {
        id: event.id,
      },
      soccerTeamScore: parseInt(event.guessTeam1Control),
      adversaryScore: parseInt(event.guessTeam2Control),
    };
    this.scoreGuessService.postScoreGuess(guess).subscribe((data) => {
      console.log('data', data);
      this.getAllGames();
    });
    return;
  }
}
