import { Component, OnInit } from '@angular/core';
import { PlanService } from 'src/app/core/services/plan.service';
import { MyPlanCardComponent } from 'src/app/shared/components/my-plan-card/my-plan-card.component';
import {
  MemberPlanDetailModel,
  Plan,
  PlanDashboardTO,
  PlanUpgradeRenewal,
} from 'src/app/shared/models/planModel';
import { UpgradeCardComponent } from '../../shared/components/upgrade-card/upgrade-card.component';
import { InvoiceModelPublic } from 'src/app/shared/models/invoiceModel';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InvoiceTableComponent } from 'src/app/shared/components/invoice-table/invoice-table.component';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import {
  MemberService,
  MemberSituationEnum,
} from 'src/app/core/services/member.service';
import { MemberShipCardsDependentsModel } from 'src/app/shared/models/memberModel';
import { ModalService } from 'src/app/core/services/modal.service';
import { MembershipCardModalComponent } from 'src/app/shared/components/membershipcard-modal/membershipcard-modal.component';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-meu-plano',
  standalone: true,
  imports: [
    CommonModule,
    MyPlanCardComponent,
    UpgradeCardComponent,
    InvoiceTableComponent,
    AppButtonComponent,
    MembershipCardModalComponent,
  ],
  templateUrl: './meu-plano.component.html',
  styleUrl: './meu-plano.component.scss',
})
export class MeuPlanoComponent implements OnInit {
  plan: PlanDashboardTO;
  invoiceList: InvoiceModelPublic[];
  memberShipCardsDependents: MemberShipCardsDependentsModel[] = [];
  memberShipCard: any;
  virtualCard = localStorage.getItem('virtualCard');

  public styles: { [key: string]: string };
  carteirinhaImg = localStorage.getItem('imageLogo');

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };
  member: UserModel;
  planDetail: any;
  plansUpgrade: PlanUpgradeRenewal[] = [];
  showUpgradeCard = false;
  isSecondaryPlan = localStorage.getItem('isSecondaryPlan') === 'true';

  public trigger$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private planService: PlanService,
    private location: Location,
    private memberService: MemberService,
    private userService: UserService,
    public modalService: ModalService,
    private alertService: AlertService
  ) {
    this.member = this.userService.currentUserValue as UserModel;
  }

  ngOnInit() {
    this.getMemberPlanDetail();

    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };

    this.getMemberPlanDashboardData();

    this.memberService
      .getMemberShipCardDashboardData(this.member.id)
      .subscribe((res) => {
        this.memberShipCard = res;
      });

    this.memberService
      .getMemberDashboardData(this.member.id)
      .subscribe((dataMember) => {
        if (dataMember) {
          this.memberService
            .getMemberShipCardsDependentsDashboardData(dataMember.id)
            .subscribe((res) => {
              this.memberShipCardsDependents = res;
            });
        } else {
          const dataMember2 = localStorage.getItem('data-member');
          if (dataMember2) {
            this.memberService
              .getMemberShipCardsDependentsDashboardData(
                JSON.parse(dataMember2).id
              )
              .subscribe((res) => {
                this.memberShipCardsDependents = res || [];
              });
          }
        }
      });
  }

  getMemberPlanDashboardData() {
    this.memberService
      .getMemberPlanDashboardData(this.member.id, this.isSecondaryPlan)
      .subscribe({
        next: (res) => {
          this.plan = res;

          if (this.plan) {
            this.memberService
              .getMembeInvoiceDashboardData(this.plan.id)
              .subscribe((invoices) => {
                invoices.forEach((invoice) => {
                  invoice.referenceMonthSort = new Date(
                    parseInt(invoice.refYear),
                    parseInt(invoice.refMonth) - 1
                  );
                  invoice.refMonth = invoice.refMonth
                    ?.toString()
                    ?.padStart(2, '0');
                });
                invoices.sort(
                  (a, b) =>
                    new Date(b.referenceMonthSort).getTime() -
                    new Date(a.referenceMonthSort).getTime()
                );
                this.invoiceList = invoices;
              });
          }
        },
        error: (e) => {
          console.error('Error:', e);
          this.alertService.showAlert(AlertType.WARNING, e.error.message);
        },
      });
  }

  getMemberPlanDetail() {
    this.planService.getCurrentPlanDetail(this.member.id).subscribe({
      next: (res: MemberPlanDetailModel) => {
        this.planDetail = res;
        this.getPlanbyId(this.planDetail.planId);
      },
      error: (error: any) => {
        console.log('Error: ', error);
      },
    });
  }

  getPlanbyId(planId: any) {
    console.log('planDetail', this.planDetail);
    this.planService.getPlanById(planId).subscribe((plan: Plan) => {
      this.plansUpgrade = plan.planUpgrades;

      const enableUpgradeable =
        localStorage.getItem('enableUpgradeable') === 'true';
      const upgradedOrigin = this.planDetail.upgradedOrigin === true;

      this.showUpgradeCard = !!(
        this.plansUpgrade.length > 0 &&
        this.member?.memberSituation?.name === 'Adimplente' &&
        this.member?.memberPlans &&
        this.planDetail?.planStatusName === 'Vigente ' &&
        enableUpgradeable &&
        !upgradedOrigin
      );
    });
  }

  back() {
    this.location.back();
  }

  showMembershipCard(memberShipData: any) {
    this.memberShipCard = memberShipData;
    setTimeout(() => {
      this.modalService.open('membershipCardModalDependents');
    }, 100);
  }

  get memberSituationEnum() {
    return MemberSituationEnum;
  }
}
