import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Notification } from 'src/app/shared/models/notificationsModel';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { ModalNotificationsComponent } from '../modal-notifications/modal-notifications.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import { Location } from '@angular/common';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { Subject, takeUntil } from 'rxjs';
import { TruncateTextDirective } from 'src/app/shared/directives/truncate-text.directive';
import { SupportRouterService } from 'src/app/shared/services/support-router.service';

@Component({
  selector: 'app-list-notifications',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, MatIconModule, CustomCurrencyPipe, ModalNotificationsComponent, AppModalComponent, TruncateTextDirective],
  templateUrl: './list-notifications.component.html',
  styleUrl: './list-notifications.component.scss'
})
export class ListNotificationsComponent implements OnInit, OnDestroy {

  notificationsList: Notification[] = [];

  isMobile = window.innerWidth < 768;

  btnManageInvoicesOptions = {
    buttonText: 'Gerenciar Faturas',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  notificationModalId = 'notification-modal-table';
  modalId = 'modal-confirm';
  modalBodyMessage = '';
  notificationModalData: Notification;
  notificationDeleted: Notification;
  currentUser: UserModel;
  private _destroy$ = new Subject<void>();
  setFromList = false;
  modalOpened = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private notificationService: NotificationsService,
    private location: Location,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private supportRouterService:SupportRouterService
  ) {
    this.getCurrentUser();

    this.activatedRoute.params.subscribe((param: any) => {
      if (param && Object.keys(param).length) {
        this.getNotificationById(param.notificationId);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
  }

  formatDate(dateArray: number[]): string {
    const [year, month, day] = dateArray;
    const date = new Date(year, month - 1, day); // Lembre-se que o mês em JavaScript é de 0 (Janeiro) a 11 (Dezembro)

    const dayString = String(date.getDate()).padStart(2, '0');
    const monthString = String(date.getMonth() + 1).padStart(2, '0'); // Como o mês é de 0 a 11, adicionamos +1
    const yearString = date.getFullYear();

    return `${dayString}/${monthString}/${yearString}`;
  }

  ngOnInit(): void {
    this.getAllNotifications();

    // Escuta mudanças no header e atualiza a pagina lista de notificações de acordo
    this.notificationService.updateNotificationSubject
    .pipe(takeUntil(this._destroy$))
    .subscribe((resp: Notification[]) => {
      if (!this.setFromList) {
        this.notificationsList = resp;

      }
      this.setFromList = false;
    });

  }

  back() {
     this.supportRouterService.getRota() === '' ?  this.router.navigate(['/dashboard']) : this.router.navigate([this.supportRouterService.getRota()])
  }

  getCurrentUser() {
    this.currentUser = this.userService.currentUserValue as UserModel;
  }

  getAllNotifications() {
    const cpf = this.currentUser.cpf;
    const memberId = this.currentUser.id;
    this.notificationService.getAllNotifications(cpf, memberId).subscribe((notifications) => {
      this.notificationsList = this.orderByUnread(notifications);
      this.notificationsList.forEach((notification:any) => {
        notification.createdAt = this.formatDate(notification.createdAt);
      });
      this.notificationsList.sort((a, b) => {
        const dateA = this.parseDate(a.createdAt);
        const dateB = this.parseDate(b.createdAt);
        return dateB.getTime() - dateA.getTime(); // Ordena do mais recente para o mais antigo
      });
      console.log(this.notificationsList)
    });
  }

  parseDate(createdAt: string): Date {
      // Assume que a data está no formato 'dd/mm/yyyy'
      const [day, month, year] = createdAt.split('/').map(Number);
      return new Date(year, month - 1, day);
  }

  getNotificationById(id: string) {
    const memberId = this.currentUser.id;
    this.notificationService.getNotificationById(id, memberId).subscribe((notification) => {
      this.viewNotification(notification);
    });
  }

  orderByUnread(notificationsList: Notification[]): Notification[] {
    return notificationsList.sort(function (x) {
      return (x.isViewed === true) ? 0 : x ? -1 : 1;
    });
  }

  deleteNotification(notification: Notification) {
    this.notificationDeleted = notification;
    this.modalBodyMessage = `Deseja realmente excluir a notificação ${notification.title}?`;
    this.openModal(notification, this.modalId);
  }

  openModal(notification: Notification, modalId: string) {
    this.notificationModalData = notification;
    this.modalService.open(modalId);
  }

  viewNotification(notification: Notification): void {
    const memberId = this.currentUser.id;

    this.notificationService.setNotificationViwed(notification.id, memberId).subscribe({
      next: () => {
        this.openModal(notification, this.notificationModalId);
        this.notificationsList.find((item) => item.id === notification.id ? item.isViewed = true : '');
        // Update notification list on header
        this.setFromList = true;
        this.notificationService.setUpdateNotificationSubject(this.notificationsList);
      }
    });
  }

  viewNotificationModalConfirm(): void {
    this.router.navigate(['/lista-notificacoes']);
  }

  onModalConfirmDelete(): void {
    const memberId = this.currentUser.id;

    this.notificationService.deleteNotification(this.notificationDeleted.id, memberId).subscribe({
      next: () => {
        this.notificationsList = this.notificationsList.filter((item) => item.id !== this.notificationDeleted.id);

        // Update notification list on header
        this.setFromList = true;
        this.notificationService.setUpdateNotificationSubject(this.notificationsList);

        this.alertService.showAlert(AlertType.SUCCESS, 'Notificação excluída com sucesso!');
        this.refreshPage()
      }
    });
  }

  refreshPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; // Desabilita o reuso da rota
    this.router.onSameUrlNavigation = 'reload'; // Habilita a recarga na mesma URL
    this.router.navigate([this.router.url], { relativeTo: this.activatedRoute });

  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }
}
