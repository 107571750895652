import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { PlanService } from 'src/app/core/services/plan.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InvoiceModel } from 'src/app/shared/models/invoiceModel';
import {
  MemberPlanDetailModel,
  PlanDashboardTO,
} from 'src/app/shared/models/planModel';
import { UserModel } from 'src/app/shared/models/userModel';
import { MemberFinalScoreModel } from 'src/app/shared/models/member-final-score';
import { MyPlanCardComponent } from '../../shared/components/my-plan-card/my-plan-card.component';
import { ModalService } from 'src/app/core/services/modal.service';
import {
  CreditCardInfoModel,
  PaymentType,
} from 'src/app/shared/models/paymentData';
import { CommonService } from 'src/app/core/services/common.service';
import { InvoiceTableComponent } from 'src/app/shared/components/invoice-table/invoice-table.component';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import {
  MemberService,
  MemberSituationEnum,
} from 'src/app/core/services/member.service';
import { PhonePipe } from 'src/app/shared/pipe/phone.pipe';
import { CepPipe } from 'src/app/shared/pipe/cep.pipe';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { MemberShipCardsDependentsModel } from 'src/app/shared/models/memberModel';
import { TermsService } from 'src/app/core/services/terms.service';
import {
  PaymentInvoiceModalComponent,
  PaymentInvoiceModalData,
} from 'src/app/shared/components/payment-invoice-modal/payment-invoice-modal.component';
import { PartnerService } from 'src/app/core/services/partner.service';
import { ExperienceService } from 'src/app/core/services/experience.service';
import { Subscription } from 'rxjs';
import { MemberFinalScoreService } from 'src/app/core/services/member-final-score.service';
import { ActiveSectionsService } from 'src/app/core/services/active-sections.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { WaveService } from 'src/app/core/services/wave.service';
import { AppFormModalComponent } from 'src/app/shared/components/app-form-modal/app-form-modal.component';
import { FormControl } from '@angular/forms';
import { FileService } from 'src/app/core/services/file.service';
import { GameService } from 'src/app/core/services/games.service';
import { GuestService } from 'src/app/core/services/guest.service';
import { SubstituteService } from 'src/app/core/services/substitute.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    AppButtonComponent,
    CommonModule,
    CustomCurrencyPipe,
    MatIconModule,
    MyPlanCardComponent,
    InvoiceTableComponent,
    PaymentInvoiceModalComponent,
    PhonePipe,
    AppModalComponent,
    AppFormModalComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  heightLastInvoice = 0;
  user: UserModel;
  memberFinalScore: MemberFinalScoreModel;
  pattern: any;
  lastInvoice: any;
  plan: PlanDashboardTO;
  cepPipe = new CepPipe();
  memberShipCard: MemberShipCardsDependentsModel | null;
  memberPlanId: any;
  isBenefitsActive: boolean = false;
  isActiveRanking: boolean = false;
  isActiveExperiences: boolean = false;
  isActiveCheckins: boolean = false;
  modalId = 'payment-modal';
  orderModalId = 'orderModal';
  PaymentType = PaymentType;
  paymentType: PaymentType;
  paymentInvoiceModalData: PaymentInvoiceModalData;
  showDashboard = false;
  canChekPix = true;
  showContinuousMonth = localStorage.getItem('showContinuousMonth') === 'true';

  public styles: { [key: string]: string };
  carteirinhaImg = localStorage.getItem('imageLogo');
  virtualCard = localStorage.getItem('virtualCard');

  btnUpgradeOptions = {
    buttonText: 'Fazer Upgrade',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-small px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000',
    fontSize: 12,
  };

  btnInvoiceDetailsOptions = {
    buttonText: 'Detalhar Fatura',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm btn-primary fs-xsmall',
    fontSize: 12,
  };

  btnUpdateDataOptions = {
    buttonText: 'Atualizar Dados',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnRankDetailsOptions = {
    buttonText: 'Mais detalhes',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnExperienceOptions = {
    buttonText: 'Ver mais experiências',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnGuessesOptions = {
    buttonText: 'Ver palpites',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnPartnershipsCouponsOptions = {
    buttonText: 'Ver todos cupons',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnRescueOptions = {
    buttonText: 'Resgatar',
    borderRadius: '25px',
    buttonSize: 'btn fs-xsmall px-2 py-1 btn-primary',
    fontSize: 12,
  };

  checkinButtonOptions = {
    buttonText: 'Realizar Check-in',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm btn-primary fs-xsmall',
    fontSize: 12,
  };

  invoiceList: any[];
  invoice: InvoiceModel;

  //card checkin
  showDiv = false;
  ingressosCheckIn: any[] = [];
  checkinsHistory: any = [];
  limitCheckin: any;
  checkinSelected: any;
  selectInputOptionsModal: SelectModel[] = [];
  memberDependents: any;
  selectControlModal: FormControl = new FormControl();
  teamLogo: string = '';
  guestSelect: any;
  substituteSelect: any;
  linkModalConfirm: string = '';
  pendentTicket: any;
  showIndicators: boolean = false;

  orderModalBodyMessage =
    'Você já possui um pedido de plano em andamento. Deseja continuar com o pedido ou cancelar?';
  termsModalBodyMessage: string = '';

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);
  partners: any;
  experiences: any;
  secondaryPlanId = JSON.parse(
    localStorage.getItem('secondaryPlanDetail') as string
  )?.planId;

  width!: any;
  //
  resizeSubscription!: Subscription;
  //

  constructor(
    private userService: UserService,
    private planService: PlanService,
    private memberFinalScoreService: MemberFinalScoreService,
    private router: Router,
    private partnerService: PartnerService,
    private termsService: TermsService,
    private modalService: ModalService,
    private memberService: MemberService,
    public commonService: CommonService,
    private activeSectionsService: ActiveSectionsService,
    private experienceService: ExperienceService,
    private alertService: AlertService,
    private waveService: WaveService,
    private cdr: ChangeDetectorRef,
    private fileService: FileService,
    private gameService: GameService,
    private guestService: GuestService,
    private substituteService: SubstituteService
  ) {
    this.user = this.userService.currentUserValue as UserModel;
  }

  ngOnInit(): void {
    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };
    const stringContinuousMonth = localStorage.getItem(
      'showContinuousMonth'
    ) as string;
    this.showContinuousMonth = stringContinuousMonth === 'true';

    this.getActiveSections();
  }

  ngAfterViewInit(): void {
    this.getMemberPublicInfo();
    this.getLogo();
  }

  // card checkin

  getAdversaryLogo(ingresso: any) {
    return this.fileService.getDocDownloadUrl(
      ingresso.waveGame.adversary.document.id
    );
  }

  getLogo() {
    this.gameService.getNextMatches().subscribe({
      next: (response: any) => {
        let nextMatchesResponse = response;
        this.teamLogo = this.commonService.getImageStr(
          nextMatchesResponse.logo.id
        );
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }

  async openCheckInModal(event: any): Promise<void> {
    try {
      this.checkinsHistory = await this.waveService
        .getCheckinsRealizados(event, this.plan.id)
        .toPromise();
      this.limitCheckin = await this.waveService
        .getLimitByMemberPlan(this.plan.id)
        .toPromise();
    } catch (error) {
      console.error('Error:', error);
    }
    this.checkinSelected = event;
    this.modalService.open('modalCheckinTicket');
  }

  async onModalCheckinConfirm(event: any): Promise<void> {
    const waveSelect = this.ingressosCheckIn.find(
      (i: any) => i.id === this.checkinSelected
    );
    let dependentSelect: any;
    if (event.isDependent) {
      dependentSelect = this.memberDependents.content.find(
        (i: any) => i.id === event.clientSelected
      );
    }

    if (event.isGuest && event.guestData) {
      let guestPayload: any = {
        id: event.guestData.idControl ? event.guestData.idControl : '',
        name: event.guestData.nameControl,
        cpf: event.guestData.cpfControl,
        email: event.guestData.emailControl,
      };

      try {
        this.guestSelect = await this.guestService
          .createGuestCheckin(guestPayload)
          .toPromise();
      } catch (error) {
        console.error('Error:', error);
      }
    }

    if (event.isSubstitute && event.substituteData) {
      let substitutePayload: any = {
        id: event.substituteData.idControl
          ? event.substituteData.idControl
          : '',
        name: event.substituteData.nameControl,
        cpf: event.substituteData.cpfControl,
        email: event.substituteData.emailControl,
      };

      try {
        this.substituteSelect = await this.substituteService
          .createSubstituteCheckin(substitutePayload)
          .toPromise();
      } catch (error) {
        console.error('Error:', error);
      }
    }

    let ticketPayload: any = {
      customer: {
        name: event.isDependent
          ? dependentSelect?.name
          : event.isGuest
          ? event.guestData.nameControl
          : event.isSubstitute
          ? event.substituteData.nameControl
          : this.pattern?.name,
        doc: event.isDependent
          ? dependentSelect?.document
          : event.isGuest
          ? event.guestData.cpfControl
          : event.isSubstitute
          ? event.substituteData.cpfControl
          : this.pattern?.cpf,
        email: event.isDependent
          ? dependentSelect?.email
          : event.isGuest
          ? event.guestData.emailControl
          : event.isSubstitute
          ? event.substituteData.emailControl
          : this.pattern?.emails[0]?.email,
        mifare: event.isDependent
          ? dependentSelect?.membershipCard?.cardNumber
            ? dependentSelect?.membershipCard?.cardNumber
            : ''
          : `1${this.pattern.cpf}`,
        phones: [this.pattern.phones[0].number],
      },
      tickets: [
        {
          ticketId: event.ticketSelected,
          ticketValue: event.isDependent
            ? waveSelect.waveTicketsDependent.find(
                (i: any) => i.ticketId === event.ticketSelected
              ).ticketValue
            : event.isGuest
            ? waveSelect.waveTicketsGuest.find(
                (i: any) => i.ticketId === event.ticketSelected
              ).ticketValue
            : waveSelect.waveTicketsSocio.find(
                (i: any) => i.ticketId === event.ticketSelected
              ).ticketValue,
          customer: {
            name: event.isDependent
              ? dependentSelect?.name
              : event.isGuest
              ? event.guestData.nameControl
              : event.isSubstitute
              ? event.substituteData.nameControl
              : this.pattern?.name,
            doc: event.isDependent
              ? dependentSelect?.document
              : event.isGuest
              ? event.guestData.cpfControl
              : event.isSubstitute
              ? event.substituteData.cpfControl
              : this.pattern?.cpf,
            email: event.isDependent
              ? dependentSelect?.email
              : event.isGuest
              ? event.guestData.emailControl
              : event.isSubstitute
              ? event.substituteData.emailControl
              : this.pattern?.emails[0]?.email,
            mifare: event.isDependent
              ? dependentSelect?.membershipCard?.cardNumber
                ? dependentSelect?.membershipCard?.cardNumber
                : ''
              : `1${this.pattern.cpf}`,
            phones: [this.pattern.phones[0].number],
          },
        },
      ],
    };

    this.waveService.realizarCheckin(ticketPayload).subscribe({
      next: (res: any) => {
        if (res?.mandatoryFace && event?.isGuest) {
          this.linkModalConfirm = res?.link;
          this.modalService.open('modalConfirm');
        }

        if (res.orderStatus && res?.orderStatus === 'Initial') {
          this.pendentTicket = res;
          this.modalService.open('modalChangeCheckin');
        } else if (res?.mandatoryFace) {
          window.open(res?.link, '_blank');
        } else {
          let checkinPayload: any = {
            waveId: this.checkinSelected,
            ticketId: event.isDependent
              ? waveSelect.waveTicketsDependent.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).id
              : event.isGuest
              ? waveSelect.waveTicketsGuest.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).id
              : waveSelect.waveTicketsSocio.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).id,
            memberPlanId: this.plan.id,
            memberDependentId: event.isDependent ? event.clientSelected : '',
            guestCheckinId: event.isGuest ? this.guestSelect?.id : '',
            substituteCheckinId: event.isSubstitute
              ? this.substituteSelect?.id
              : '',
            ticketLink: res?.link || '',
            ticketValue: event.isDependent
              ? waveSelect.waveTicketsDependent.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).ticketValue
              : event.isGuest
              ? waveSelect.waveTicketsGuest.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).ticketValue
              : waveSelect.waveTicketsSocio.find(
                  (i: any) => i.ticketId === event.ticketSelected
                ).ticketValue,
            ticketCode: res?.codes[0] || '',
            orderId: res.orderId,
          };
          this.waveService.criarCheckin(checkinPayload).subscribe((data) => {
            this.alertService.showAlert(
              AlertType.SUCCESS,
              'Checkin realizado com sucesso.'
            );
            window.open(res.link, '_blank');
            window.location.reload();
          });
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }

  getMemberPublicInfo() {
    this.memberService.getMemberPublicInfo(this.user.cpf).subscribe((res) => {
      this.user = res;
      if (this.user?.termsAccepted == false) {
        this.showTermsModal();
      } else {
        // Verifica se o socio possui plano ou tem situação bloqueada
        if (
          !this.secondaryPlanId &&
          (this.user?.memberPlans?.length == 0 ||
            this.memberService.isMemberSituationInEnum(
              this.user?.memberSituation?.id as string
            ))
        ) {
          if (
            this.user?.memberSituation?.id === MemberSituationEnum.NaoRenovado
          ) {
            this.planService.getLastExpiredMemberPlan(this.user.id).subscribe({
              next: (res: MemberPlanDetailModel) => {
                const lastExpiredPlan = res;
                if (lastExpiredPlan?.planEndDate) {
                  const planEndDate = new Date(lastExpiredPlan?.planEndDate);
                  const today = new Date();
                  if (
                    planEndDate.getMonth() === today.getMonth() &&
                    planEndDate.getFullYear() === today.getFullYear()
                  ) {
                    this.router.navigate(['/renovar-plano'], {
                      queryParams: { newAdesion: false },
                    });
                  } else {
                    this.router.navigate(['/selecionar-plano']);
                  }
                } else {
                  this.router.navigate(['/selecionar-plano']);
                }
              },
              error: (error: any) => {
                console.error('Error: ', error);
                this.router.navigate(['/selecionar-plano']);
              },
            });
          } else {
            this.router.navigate(['/selecionar-plano']);
          }
        } else {
          this.getExperiences();
          this.getPartners();
          this.getMemberFinalScore();

          if (this.user?.memberPlans?.length == 0) return;
          this.getMemberDashboardData();

          this.getMemberPlanDashboardData();
          const creditCardArr =
            this.commonService.getCreditCardInfoFromLocalStorage();
          this.creditCardInfo.set(creditCardArr);
        }
      }
    });
  }

  getMemberPlanDashboardData() {
    const isSecondaryPlan = localStorage.getItem('isSecondaryPlan') === 'true';
    this.memberService
      .getMemberPlanDashboardData(this.user.id, isSecondaryPlan)
      .subscribe({
        next: (res) => {
          this.plan = res;
          this.memberPlanId = res.id;
          if (this.plan) {
            this.getNextActivesWaves();
            this.getMembeInvoiceDashboardData();
            this.getLastMembeInvoiceDashboardData();
          }
          this.showDashboard = true;
          this.cdr.detectChanges();
        },
        error: (e) => {
          console.error('Error:', e);
          this.alertService.showAlert(AlertType.WARNING, e.error.message);
        },
      });
  }

  getMembeInvoiceDashboardData() {
    this.memberService
      .getMembeInvoiceDashboardData(this.plan.id)
      .subscribe((invoices) => {
        invoices.forEach((invoice) => {
          // Mantém os valores nulos se refMonth ou refYear forem nulos
          const refMonth = invoice.refMonth !== null ? invoice.refMonth : null;
          const refYear = invoice.refYear !== null ? invoice.refYear : null;
          invoice.referenceMonthSort =
            refYear !== null && refMonth !== null
              ? new Date(parseInt(refYear), parseInt(refMonth) - 1)
              : null;
          invoice.refMonth =
            refMonth !== null ? refMonth.toString().padStart(2, '0') : null;
        });
        invoices.sort((a, b) => {
          if (a.referenceMonthSort && b.referenceMonthSort) {
            return (
              new Date(b.referenceMonthSort).getTime() -
              new Date(a.referenceMonthSort).getTime()
            );
          }
          return 0;
        });
        this.invoiceList = invoices;
      });
  }

  getLastMembeInvoiceDashboardData() {
    this.memberService
      .getLastMembeInvoiceDashboardData(this.plan.id)
      .subscribe((lastInvoices) => {
        this.lastInvoice = lastInvoices;
      });
  }

  getNextActivesWaves() {
    this.waveService
      .getNextActivesWaves(this.plan.plan.id)
      .subscribe((data) => {
        this.ingressosCheckIn = data;
        this.showIndicators = this.ingressosCheckIn.length > 1;
        this.showDiv = true;
      });
  }

  getMemberDependentesByMember() {
    this.memberService
      .getMemberDependentesByMember(this.pattern.id)
      .subscribe((data) => {
        this.memberDependents = data;
        data.content.forEach((dep) => {
          let optionDep: SelectModel = {
            code: dep.id,
            name: `${dep.name} (Dependente)`,
          };
          this.selectInputOptionsModal.push(optionDep);
        });
      });
  }

  getMemberShipCardDashboardData() {
    this.memberService
      .getMemberShipCardDashboardData(this.pattern.id)
      .subscribe((res) => {
        this.memberShipCard = res;
      });
  }

  getMemberDashboardData() {
    this.memberService.getMemberDashboardData(this.user.id).subscribe((res) => {
      this.pattern = res;
      let option: SelectModel = {
        code: res.id,
        name: `${res.name} (Sócio)`,
      };
      this.selectInputOptionsModal.push(option);
      if (this.pattern) {
        this.getMemberDependentesByMember();
      }
      this.getMemberShipCardDashboardData();
    });
  }

  showOptionsPayment(): boolean {
    switch (this.lastInvoice?.invoicePaymentStatus?.statusTypeName) {
      case 'Aberto':
        return true;
        break;
      case 'Vencido':
        return true;
        break;
      case 'Rejeitado':
        return true;
        break;
      case 'Não Autorizado':
        return true;
        break;
      /* case 'Cancelado':
        return true
        break; */
      default:
        return false;
        break;
    }
  }

  getExperiences() {
    this.experienceService.getExperiences().subscribe((res: any) => {
      this.experiences = res.content.slice(0, 3);
      this.experiences.forEach((experience: any) => {
        experience.src = this.commonService.getImageStr(experience.document.id);
      });
    });
  }

  getActiveSections() {
    this.activeSectionsService.getActiveSections().subscribe({
      next: (res: any) => {
        this.isBenefitsActive = res.sections?.parceiros || false;
        this.isActiveRanking = res.sections?.ranking || false;
        this.isActiveExperiences = res.sections?.experiencias || false;
        this.isActiveCheckins =
          (res.sections?.ingressos &&
            this.user?.memberSituation?.id ===
              this.memberSituationEnum.Adimplente) ||
          false;
      },
    });
  }

  getPartners() {
    this.partnerService.getAllPartners().subscribe((partners: any) => {
      this.partners = partners.content.slice(0, 3);
      this.partners.forEach((partner: any) => {
        partner.src = this.commonService.getImageStr(partner.document.id);
      });
    });
  }

  getTrendClass(): string {
    if (!this.memberFinalScore || !this.memberFinalScore.trend) {
      return '';
    }
    switch (this.memberFinalScore.trend) {
      case 'up':
        return 'bi-caret-up-fill text-success';
      case 'down':
        return 'bi-caret-down-fill text-danger';
      case 'same':
        return 'bi-dash text-warning';
      default:
        return '';
    }
  }

  getMemberFinalScore() {
    this.memberFinalScoreService
      .getByMemberId(this.user.id)
      .subscribe((res: any) => {
        this.memberFinalScore = res;
      });
  }

  setHeight(event: any) {
    this.heightLastInvoice = event;
  }

  openModal(paymentType: PaymentType) {
    this.paymentInvoiceModalData = {
      invoiceId: this.lastInvoice.id,
    };
    this.paymentType = paymentType;
    setTimeout(() => {
      this.modalService.open(this.modalId);
    }, 100);
  }

  goToPartnerData() {
    this.router.navigate(['/dados-de-socio']);
  }

  goToExperiences() {
    this.router.navigate(['/experiencias']);
  }

  onModalConfirm(event: any): void {
    console.log('onModalConfirm', event);
  }

  goToInvoiceDetail(invoice: any): void {
    // TODO: Mudar approach se o invoice obj for muito grande
    this.router.navigate(['/ver-fatura'], { state: { invoice: invoice } });
  }

  rankDetail(): void {
    this.router.navigate(['/ranking-de-socios']);
  }
  goToRescue(): void {
    this.router.navigate(['/resgates']);
  }

  showAllCoupons(): void {
    this.router.navigate(['/parcerias']);
  }

  getAddress() {
    if (this?.pattern?.addresses[0]) {
      let address = this?.pattern?.addresses[0];
      return `${address?.area},  ${
        address?.number != null ? address?.number : 'S/N'
      }, ${address?.complement}, ${address?.neighborhood}, ${
        address?.cityName
      } - ${address?.stateName},  CEP: ${this.cepPipe.transform(
        address?.zipCode
      )} `;
    } else {
      return '-';
    }
  }

  showTermsModal() {
    this.termsService.getTerms().subscribe((data) => {
      this.termsModalBodyMessage = data[0].content;
      this.modalService.open('termsModal');
    });
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  onTermsModalConfirm() {
    this.memberService.setAcceptTerms(this.user.id).subscribe((res) => {});
  }

  showCarteirinha() {
    this.modalService.open('membershipCardModal');
  }

  get memberSituationEnum() {
    return MemberSituationEnum;
  }
}
