import { Component, OnDestroy, OnInit, input, model } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from '../app-button/app-button.component';

declare var bootstrap: any;

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './app-modal.component.html',
  styleUrl: './app-modal.component.scss',
})
export class AppModalComponent implements OnInit, OnDestroy {
  modalId = input.required<string>();
  modalTitle = input<string>('Modal de confirmação');
  modalBodyMessage = input.required<string>();
  modalConfirmLabel = input<string>('');
  modalCancelLabel = input<string>('');
  modalThirdLabel = input<string>('');
  showThirdBtn = input<boolean>(false);
  showCloseBtn = input<boolean>(true);
  size = input<string>('none');
  scrollable = input<boolean>(false);
  backdropType = input<string>();
  isConfirmEnabled = false;

  confirmAction = model();
  thirdAction = model(); // Nova ação para o terceiro botão

  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-sm px-5 btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Confirmar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000',
  };

  thirdBtn = {
    buttonText: 'Terceiro Botão',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 btn-secondary-stroked',
  };

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe(
      (state) => {
        if (state.id === this.modalId()) {
          const modalElement = document.getElementById(state.id);
          if (modalElement) {
            if (state.action === 'open') {
              const modal = new bootstrap.Modal(modalElement);
              modal.show();
            } else if (state.action === 'close') {
              const modal = bootstrap.Modal.getInstance(modalElement);
              if (modal) {
                modal.hide();
              }
            }
          }
        }
      }
    );

    this.setBtnLabel();
  }

  setBtnLabel(): void {
    if (this.modalCancelLabel() !== '')
      this.cancelBtn.buttonText = this.modalCancelLabel();
    if (this.modalConfirmLabel() !== '')
      this.confirmBtn.buttonText = this.modalConfirmLabel();

    if (this.modalThirdLabel() !== '')
      this.thirdBtn.buttonText = this.modalThirdLabel();
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  onConfirm() {
    this.confirmAction.set(true);
    this.modalService.close(this.modalId());
  }

  onCancel() {
    this.confirmAction.set(false);
    this.modalService.close(this.modalId());
  }

  onThirdAction() {
    this.thirdAction.set(true);
    this.modalService.close(this.modalId());
  }
}
