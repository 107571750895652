import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';
import { StyleContentBenefitTable } from 'src/app/shared/models/styleContent';
import { StyleContentSectionBenefit } from 'src/app/shared/models/styleContent';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService
  ) {
    super(http, errorHandlerService);
  }

  getAllBenefits() {
    let params = new HttpParams();
    params = params.append('pageSize', '999');
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/benefits`, { params });
  }

  getTableBenefitsStyles(): Observable<StyleContentBenefitTable> {
    return this.get<StyleContentBenefitTable>(`${this.env.publicApiSocioUrl}v1/socio/benefit-table-sections`);
  }


  getBannerConfig(): Observable<StyleContentSectionBenefit> {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/banner`);
  }
}
