<app-button
  class="align-self-end"
  [buttonOptions]="btnFuturePlansOptions"
  (buttonClick)="openModal()"
></app-button>

<div
  #futurePlansModal
  class="modal fade"
  id="modal-future-plans"
  tabindex="-1"
  aria-labelledby="modalFuturePlansLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-5" id="modalFuturePlansLabel">
          Planos Futuros
        </div>
        <button
          type="button"
          class="btn-close"
          (click)="closeModal()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 mb-4" *ngFor="let plan of futurePlans">
              <div class="card h-100">
                <div class="card-body">
                  <div class="card-title h6">{{ plan.planName }}</div>
                  <div class="card-text">
                    <div class="mb-2">
                      <b>Data de Início:</b>
                      {{ plan.planStartDate | date : "dd/MM/yyyy" }}<br />
                    </div>
                    <div class="mb-2">
                      <b>Validade do Plano:</b>
                      {{ plan.planEndDate | date : "dd/MM/yyyy" }}<br />
                    </div>
                    <div class="mb-2">
                      <b>Valor:</b>
                      {{ plan.monthlyFee | customCurrency }}<br />
                    </div>
                    <div class="mb-2">
                      <b>Periodicidade:</b> {{ plan.periodicity | titlecase }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
