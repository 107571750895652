<div class="card invoice-card" [ngClass]="{'fixed-height-invoice-card': !fullMode()}" [ngStyle]="styles">
    <div class="card-body row mx-0 px-0" [ngClass]="{'my-0 py-0': fullMode()}">
        <div class="d-flex justify-content-between align-items-top mb-2" [ngClass]="{'align-items-center': fullMode()}">
            <div class="card-title m-0" [ngClass]="{'align-items-center': fullMode()}">Minhas Faturas</div>
            <div [ngClass]="{'m-2': fullMode()}">
                <app-button id="btn-manage-invoice" class="text-center align-content-end"
                    [buttonOptions]="btnAntecipationInvoicesOptions" (buttonClick)="goToAntecipation()"></app-button>
            </div>
        </div>
        <div class="table-responsive px-0 mt-0 custom-scrollbar">
            <table class="table table-striped">
                <thead>
                    <tr class="text-center">
                        <!-- <th scope="col">ID</th> -->
                        <th scope="col">TIPO</th>
                        <th scope="col">REF</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Vencimento</th>
                        <th scope="col">Pagamento</th>
                        <th scope="col">Status</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="invoicesPerPage()?.length else noInvoiceItems">
                        @for (item of invoicesPerPage(); track $index) {
                        <tr class="text-center">
                            <td>{{item.invoiceType?.name }}</td>
                            <td>{{ item.refMonth ? item.refMonth + '/' + item.refYear : '-'}}</td>
                            <td>{{ item.amount ? (item.amount | customCurrency) : '-' }}</td>
                            <td>{{item.dueDate | date:'dd/MM/yyyy':'UTC'}}</td>
                            <td>{{item.paymentDate | date:'dd/MM/yyyy':'UTC'}}</td>
                            <td><i *ngIf="item?.invoicePaymentStatus?.statusTypeName == 'Pendente'"
                                    class="bi bi-info-circle-fill mx-1"></i>{{item?.invoicePaymentStatus?.statusTypeName}}
                            </td>
                            <td class="d-flex justify-content-center actions">
                                @if (item?.invoicePaymentStatus?.statusTypeName != 'Pago' &&
                                item?.invoicePaymentStatus?.statusTypeName != 'Cancelado') {
                                <div class="payment-options-table d-flex justify-content-evenly"
                                    [ngClass]="{'disabled-actions': !isPayable(item)}">
                                    <mat-icon id="table-pix" class="pix-mat-icon cursor-pointer align-self-center"
                                        svgIcon="pix-green" (click)="openModal(PaymentType.PIX, item)"></mat-icon>
                                    <i id="table-credit" class="bi bi-credit-card cursor-pointer align-self-center"
                                        (click)="openModal(PaymentType.CREDITO, item)"></i>
                                </div>
                                }
                                <div class="d-flex" (click)="goToInvoiceDetail(item.id)">
                                    <i class="bi bi-clipboard2-data-fill mx-2 cursor-pointer align-self-center"></i>
                                </div>
                            </td>
                        </tr>
                        }

                    </ng-container>
                </tbody>
            </table>

            <div class="pagination" *ngIf="fullMode()">
                <button (click)="prevPage()" [disabled]="currentPage() === 1">
                    <i class="bi bi-arrow-left-circle-fill"></i>
                </button>
                <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
                    <button (click)="goToPage(i + 1)" [class.active]="i + 1 === currentPage()">
                        {{ i + 1 }}
                    </button>
                </ng-container>
                <button (click)="nextPage()" [disabled]="currentPage() === totalPages">
                    <i class="bi bi-arrow-right-circle-fill"></i>
                </button>
            </div>
        </div>

        <app-button *ngIf="!fullMode()" id="btn-manage-invoice" class="align-content-center mt-2"
            [buttonOptions]="btnManageInvoicesOptions" (buttonClick)="goToUrl()"></app-button>
    </div>
</div>


<app-payment-invoice-modal [modalId]="modalId" [paymentType]="paymentType"
    [paymentInvoiceModalData]="paymentInvoiceModalData" [backdropType]="'static'"
    (confirmActionChange)="onModalConfirm()"></app-payment-invoice-modal>

<ng-template #noInvoiceItems>
    <div class="p-3">Ainda não existem faturas!</div>
</ng-template>