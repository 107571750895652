import { CommonModule, Location } from '@angular/common';
import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import {
  MemberService,
  MemberSituationEnum,
} from 'src/app/core/services/member.service';
import { CommonService, DateMode } from 'src/app/core/services/common.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import {
  FreePaymentDataPayload,
  PaymentDataPayload,
  PaymentDataService,
  RecurrentUpgradeTO,
} from 'src/app/core/services/payment-data.service';
import {
  AppToggleButtonComponent,
  BtnToggleOptions,
} from 'src/app/shared/components/app-button-group/app-toggle-button.component';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import {
  AppSelectInputComponent,
  SelectModel,
} from 'src/app/shared/components/app-select-input/app-select-input.component';
import { CardPlanItemsComponent } from 'src/app/shared/components/card-plan-items/card-plan-items.component';
import {
  CreditCardInfoModel,
  PaymentFrequencyId,
  PaymentInfoModel,
  PaymentType,
  PaymentTypeId,
} from 'src/app/shared/models/paymentData';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { PlanService } from 'src/app/core/services/plan.service';
import { PaymentModalComponent } from 'src/app/shared/components/payment-modal/payment-modal.component';
import { ModalService } from 'src/app/core/services/modal.service';
import {
  AppInputCustomComponent,
  InputMaskTypes,
} from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import {
  AppInputComponent,
  InputType,
} from 'src/app/shared/components/app-input/app-input.component';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PlanPaymentsService } from 'src/app/core/services/plan-payments.service';
import { PlanPaymentUpgradeModel } from 'src/app/shared/models/planPaymentsModel';
import { PostPublicMemberPlansModel } from 'src/app/shared/models/memberModel';
import { MemberPlanDetailModel } from 'src/app/shared/models/planModel';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/userModel';

@Component({
  selector: 'app-finalizar-pagamento',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppSelectInputComponent,
    AppCheckboxComponent,
    AppButtonComponent,
    AppToggleButtonComponent,
    CustomCurrencyPipe,
    CardPlanItemsComponent,
    RouterModule,
    PaymentModalComponent,
    AppInputCustomComponent,
    AppInputComponent,
  ],
  templateUrl: './finalizar-pagamento.component.html',
  styleUrl: './finalizar-pagamento.component.scss',
})
export class FinalizarPagamentoComponent implements OnInit {
  // TODO: Rever estrutura de color
  labelColor = 'white';
  screenWidth: number;

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;
  DateMode = DateMode;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Finalize o pagamento';
  PaymentType = PaymentType;
  paymentType = PaymentType.CREDITO;
  paymentModalData: any;
  currentUserData: any;
  canChekPix = true;
  showCanUseCardControl = true;

  modalId = 'payment-modal';
  submitCount: number = 0;
  showDependentsOptions = false;

  finalizePaymentformGroup = new FormGroup({
    planControl: new FormControl<string>('', Validators.required),
    dependentControl: new FormControl<string>('0'),
    frequencyControl: new FormControl<string>('', Validators.required),
    invoiceRecurrenceControl: new FormControl<boolean>(false),
    formOfPaymentControl: new FormControl<string>('', Validators.required),
    creditCardControl: new FormControl<string>(''),
  });
  creditCardformGroup = new FormGroup({
    cardNumberControl: new FormControl<string>(''),
    expirationDateControl: new FormControl<string>(''),
    cvvControl: new FormControl<string>(''),
    cardNameControl: new FormControl<string>(''),
    CPFControl: new FormControl<string>(''),
    numberOfInstallmentsControl: new FormControl<string>(''),
    canUseCardControl: new FormControl<boolean>(true),
  });

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);

  creditCardOptions = computed<SelectModel[]>(() => {
    if (!this.creditCardInfo()) {
      return [];
    }

    return this.creditCardInfo().map((cardInfo) => {
      return {
        code: cardInfo.cardNumber, // Use card number as code
        name: `${cardInfo.cardOperator} - ....${cardInfo.cardNumber.slice(-4)}`,
      } as SelectModel;
    });
  });

  paymentSummary: any;
  paymentMethodType: any;

  frequencyOptions: BtnToggleOptions[] = [];

  formOfPaymentOptions: BtnToggleOptions[] = [];

  buttonOptions = {
    buttonText: 'FINALIZAR',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    buttonSize: 'btn btn-sm py-2 px-4 w-100 btn-primary',
  };

  buttonAddCardOptions = {
    buttonText: '+ Adicionar cartão',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
  };

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  showCreditCardOptions = false;

  private _destroy$ = new Subject<void>();

  numberOfInstallmentsOptions: SelectModel[] = [];

  desiredOrderFrequency = [
    'TEMPORADA',
    'ANUAL',
    'SEMESTRAL',
    'TRIMESTRAL',
    'MENSAL',
  ];

  protected planOptions = [] as SelectModel[];
  dependentsOptions = [] as SelectModel[];

  planItem: any;
  plans: PlanPaymentUpgradeModel[] = [];
  memberPlan: any;
  planSelected: any;
  benefitsPlanSelected: any;
  user: UserModel;
  deviceId: string;

  private memberId: string;
  isLoggedIn = false;

  operation: OperationType = this.paymentDataService.getOperationType();

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private paymentDataService: PaymentDataService,
    private router: Router,
    private memberService: MemberService,
    private planService: PlanService,
    private modalService: ModalService,
    private alertService: AlertService,
    private authService: AuthService,
    private planPaymentsService: PlanPaymentsService,
    private userService: UserService,
    private location: Location
  ) {
    this.addScriptMercadoPago();

    this.user = this.userService.currentUserValue as UserModel;

    this.authService.isLoggedIn.subscribe((status) => {
      this.isLoggedIn = status;
    });

    this.memberId = this.memberService.getMemberId();
    // this.memberPlan = this.localStorageService.getItem('current-user')?.memberPlans[0];
    if (!this.memberId) {
      this.router.navigate(['/cadastro-socio']);
    }
  }

  ngOnInit(): void {
    this.currentUserData = this.localStorageService.getItem('current-user');
    if (!this.currentUserData) {
      console.error('Dados do usuário não encontrados no local storage.');
    }

    this.screenWidth = window.innerWidth;
    this.planSelected = this.planService.getPlanSelected();

    if (
      this.operation === OperationType.UPGRADE ||
      this.operation === OperationType.UPGRADE_RECURRENCE
    ) {
      this.planService.getCurrentPlanDetail(this.memberId).subscribe({
        next: (res: MemberPlanDetailModel) => {
          this.memberPlan = res;
          this.getPlanPaymentsUpgrades(this.memberPlan?.id);
        },
      });
    } else if (
      this.operation === OperationType.RENEW ||
      this.operation === OperationType.EARLY_RENEW
    ) {
      if (this.user?.memberSituation?.id === MemberSituationEnum.NaoRenovado) {
        if (this.planSelected) {
          const previousPlansId = localStorage.getItem(
            'previousPlansId'
          ) as string;
          this.getRenewalsByPlan(previousPlansId);
        } else {
          this.planService.getLastExpiredMemberPlan(this.memberId).subscribe({
            next: (res: MemberPlanDetailModel) => {
              this.memberPlan = res;
              this.getRenewalsByPlan(this.memberPlan?.planId);
            },
            error: (error: any) => {
              console.error('Error: ', error);
            },
          });
        }
      } else {
        this.planService.getCurrentPlanDetail(this.memberId).subscribe({
          next: (res: MemberPlanDetailModel) => {
            this.memberPlan = res;
            this.getRenewalsByPlan(this.memberPlan?.planId);
          },
          error: (error: any) => {
            console.error('Error: ', error);
          },
        });
      }
    } else {
      this.getPlans();
    }

    // Seleção de plano
    this.finalizePaymentformGroup.controls.planControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.onPlanChange(value as string);
        this.getSummaryInfo();
      });

    // Seleção de frequência
    this.finalizePaymentformGroup.controls.frequencyControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.onFrequencyChange(value as string);
        this.getSummaryInfo();
      });

    // Seleção de forma de pagamento
    this.finalizePaymentformGroup.controls.formOfPaymentControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.setPixIconColor(value as string);
        this.updateSummary(value as PaymentType);
        this.getSummaryInfo();
      });

    const paymentInfo = {
      plan: '',
      frequency: '',
      invoiceRecurrence: true,
      formOfPayment: '',
      creditCard: '',
      numberOfInstallments: '',
    } as PaymentInfoModel;

    this.initializeForm(this.finalizePaymentformGroup, paymentInfo);

    this.setCreditCardArr();
  }

  getPlans(): void {
    this.planService.getPublicPlans().subscribe((plans: any) => {
      this.plans = plans.content;
      this.planOptions = [
        {
          code: '0',
          name: 'Selecione um plano',
        } as SelectModel,
        ...(this.plans.map((plan: any) => {
          return {
            code: plan.id,
            name: plan?.planName,
          } as SelectModel;
        }) as SelectModel[]),
      ];

      if (this.planSelected?.id) {
        // Verificar se o plano pode ter novos sócios
        this.planService
          .canAddMember(this.planSelected.id, this.currentUserData.id)
          .subscribe((canAdd: boolean) => {
            if (canAdd) {
              // Mantém o plano selecionado
              this.finalizePaymentformGroup
                .get('planControl')
                ?.setValue(this.planSelected.id);
            } else {
              // Reseta para "Selecione um plano" e exibe alerta
              this.finalizePaymentformGroup.get('planControl')?.setValue('0');
              this.alertService.showAlert(
                AlertType.WARNING,
                'O plano selecionado atingiu o limite de sócios permitidos.'
              );
            }
          });
      } else {
        // Reseta para "Selecione um plano" por padrão
        this.finalizePaymentformGroup.get('planControl')?.setValue('0');
      }
    });
  }

  addScriptMercadoPago() {
    const script = document.createElement('script');
    script.src = 'https://www.mercadopago.com/v2/security.js';
    script.setAttribute('view', 'checkout');
    script.setAttribute('output', 'deviceId');
    document.head.appendChild(script);

    script.onload = () => {
      this.deviceId =
        (window as any).deviceId || (window as any).MP_DEVICE_SESSION_ID;
    };
  }

  getRenewalsByPlan(id: string): void {
    if (!id) {
      return;
    }
    this.planPaymentsService
      .getPlanPaymentsRenewals(id)
      .subscribe((plans: PlanPaymentUpgradeModel[]) => {
        this.plans = plans;
        if (this.operation === OperationType.EARLY_RENEW) {
          this.plans = plans.filter(
            (plan) =>
              plan.paymentMethodType.id !== PaymentTypeId.CREDITO_RECORRENCIA
          );
        }

        this.planOptions = this.plans
          .map((plan: any) => {
            return {
              code: plan.plan,
              name: plan?.planName,
            } as SelectModel;
          })
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.code === item.code)
          );

        if (this.planSelected?.id) {
          this.finalizePaymentformGroup
            .get('planControl')
            ?.setValue(this.planSelected.id);
        }
      });
  }

  getPlanPaymentsUpgrades(memberPlanId: string): void {
    if (!memberPlanId) {
      return;
    }
    this.planPaymentsService
      .getPlanPaymentsUpgrades(memberPlanId)
      .subscribe((plans: PlanPaymentUpgradeModel[]) => {
        this.plans = plans;
        this.planOptions = this.plans
          .map((plan: any) => {
            return {
              code: plan.plan,
              name: plan?.planName,
            } as SelectModel;
          })
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.code === item.code)
          );
        if (this.planSelected?.id) {
          this.finalizePaymentformGroup
            .get('planControl')
            ?.setValue(this.planSelected.id);
        }
      });
  }

  updateValueOfPayment() {
    this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue(null);
    this.showCreditCardOptions = false;
    this.paymentSummary = {};
  }

  onPlanChange(value: string): void {
    this.showCreditCardOptions = false;
    // Verifica se o plano foi selecionado
    if (!value || value === '0') {
      this.frequencyOptions = [];
      this.formOfPaymentOptions = [];
      this.finalizePaymentformGroup.controls.frequencyControl.setValue('');
      this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue('');
      this.dependentsOptions = [];
      this.planItem = null;

      return;
    }

    // Verifica se é possível adicionar membros ao plano selecionado
    this.planService.canAddMember(value, this.currentUserData.id).subscribe({
      next: (canAdd: boolean) => {
        if (!canAdd) {
          // Se não for possível adicionar membros, reseta o plano e exibe uma mensagem
          this.finalizePaymentformGroup.controls.planControl.setValue('0');
          return; // Interrompe a execução do método
        }

        // Se for possível, segue com a lógica do plano
        this.planItem = this.plans?.filter(
          (plan: any) => plan?.plan === value || plan?.id === value
        ) as PlanPaymentUpgradeModel[];
        this.benefitsPlanSelected = null;

        // Tratamento para plano gratuito
        if (this.planItem[0].amount == 0) {
          this.frequencyOptions = [];
          this.formOfPaymentOptions = [];
          this.frequencyOptions = [
            {
              label: 'MENSAL',
              value: '0cebe0c2-22ee-483d-90cb-9b9f52c2ca3b',
            },
          ];
          this.finalizePaymentformGroup.controls.frequencyControl.setValue(
            '0cebe0c2-22ee-483d-90cb-9b9f52c2ca3b'
          );
          this.formOfPaymentOptions = [
            {
              label: 'Gratuito',
              value: 'ea737f0c-441b-46f6-a7d4-b139f8b16357',
            },
          ];
          this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue(
            'ea737f0c-441b-46f6-a7d4-b139f8b16357'
          );
        } else {
          this.paymentSummary = {};
          this.finalizePaymentformGroup.controls.frequencyControl.setValue('');
          this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue(
            ''
          );
          if (!this.planItem) {
            return;
          }
          if (this.planItem[0].payments) {
            this.frequencyOptions = this.planItem[0]?.payments.map(
              (payment: any) => {
                return {
                  label: payment.period.description,
                  value: payment.period.id,
                } as BtnToggleOptions;
              }
            );
          } else {
            this.frequencyOptions = this.planItem?.map((payment: any) => {
              return {
                label: payment.period.description,
                value: payment.period.id,
              } as BtnToggleOptions;
            });
          }

          this.frequencyOptions = this.frequencyOptions?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.label === item.label)
          );

          this.frequencyOptions.sort((a, b) => {
            return (
              this.desiredOrderFrequency.indexOf(a.label) -
              this.desiredOrderFrequency.indexOf(b.label)
            );
          });
        }
      },
      error: (error) => {
        if (error.status === 400) {
          // Exibe a mensagem de erro e reseta o plano
          console.error('Erro ao adicionar membro:', error.error.message);
          this.finalizePaymentformGroup.controls.planControl.setValue('0');
        }
      },
    });
  }

  onFrequencyChange(value: string): void {
    this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue('');
    this.dependentsOptions = [];
    if (!value) {
      return;
    }
    if (this.planItem[0].amount == 0) {
      if (this.finalizePaymentformGroup.controls.frequencyControl.value == '') {
        this.frequencyOptions = [
          {
            label: 'MENSAL',
            value: '0cebe0c2-22ee-483d-90cb-9b9f52c2ca3b',
          },
        ];
        this.finalizePaymentformGroup.controls.frequencyControl.setValue(
          '0cebe0c2-22ee-483d-90cb-9b9f52c2ca3b'
        );
        this.formOfPaymentOptions = [
          {
            label: 'Gratuito',
            value: 'ea737f0c-441b-46f6-a7d4-b139f8b16357',
          },
        ];
        this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue(
          'ea737f0c-441b-46f6-a7d4-b139f8b16357'
        );
      }
    } else {
      this.setNumberOfInstallments(
        this.frequencyOptions.find((item) => item.value === value)
          ?.label as string
      );

      if (this.planItem[0].payments) {
        this.formOfPaymentOptions = this.planItem[0]?.payments
          .filter((payment: any) => {
            return (
              payment.period.id ===
              this.finalizePaymentformGroup.controls.frequencyControl.value
            );
          })
          .map((paymentItem: any) => {
            // Definindo a label
            let label = paymentItem.paymentMethodType.name;

            if (
              this.screenWidth <= 470 &&
              paymentItem.paymentMethodType.name === 'Cartão de Crédito'
            ) {
              label = 'C. de Crédito'; //
            }
            return {
              label: label,
              value: paymentItem.paymentMethodType.id,
              icon:
                paymentItem.paymentMethodType.name == 'PIX'
                  ? 'pix-darker'
                  : 'bi bi-credit-card',
              iconComponent:
                paymentItem.paymentMethodType.name == 'PIX' ? 'mat-icon' : null,
            } as BtnToggleOptions;
          });
      } else {
        this.formOfPaymentOptions = this.planItem
          ?.filter((payment: any) => {
            return (
              payment.period.id ===
              this.finalizePaymentformGroup.controls.frequencyControl.value
            );
          })
          .map((paymentItem: any) => {
            // Definindo a label
            let label = paymentItem.paymentMethodType.name;

            if (
              this.screenWidth <= 470 &&
              paymentItem.paymentMethodType.name === 'Cartão de Crédito'
            ) {
              label = 'C. de Crédito'; //
            }
            return {
              label: label,
              value: paymentItem.paymentMethodType.id,
              icon:
                paymentItem.paymentMethodType.name == 'PIX'
                  ? 'pix-darker'
                  : 'bi bi-credit-card',
              iconComponent:
                paymentItem.paymentMethodType.name == 'PIX' ? 'mat-icon' : null,
            } as BtnToggleOptions;
          });
      }

      this.formOfPaymentOptions = this.formOfPaymentOptions?.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.label === item.label)
      );
    }
  }

  setNumberOfInstallments(value: string): void {
    let maxInstallments;
    switch (value) {
      case 'ANUAL':
        maxInstallments = localStorage.getItem(
          'yearMaximumNumberInstallment'
        ) as string;
        break;
      case 'TEMPORADA':
        maxInstallments = localStorage.getItem(
          'seasonMaximumNumberInstallment'
        ) as string;
        break;
      case 'SEMESTRAL':
        maxInstallments = localStorage.getItem(
          'semiAnnualMaximumNumberInstallment'
        ) as string;
        break;
      case 'TRIMESTRAL':
        maxInstallments = localStorage.getItem(
          'quarterlyMaximumNumberInstallment'
        ) as string;
        break;
      default:
        maxInstallments = '1';
        break;
    }
    this.numberOfInstallmentsOptions = [];
    for (let index = 1; index <= parseInt(maxInstallments); index++) {
      this.numberOfInstallmentsOptions.push({
        code: index.toString(),
        name: index.toString(),
      });
    }
    this.creditCardformGroup.get('numberOfInstallmentsControl')?.setValue('1');
  }

  async handleCreditCardInfo(
    creditCardArr: CreditCardInfoModel[]
  ): Promise<CreditCardInfoModel[]> {
    if (!creditCardArr.length) {
      try {
        const fetchedData = await lastValueFrom(
          this.paymentDataService.getCreditCardInfo()
        );
        this.localStorageService.setItem('credit-card-info', fetchedData);
        return fetchedData; // Return the fetched data
      } catch (error) {
        console.error('Error fetching credit card info:', error);
        throw error;
      }
    }
    return creditCardArr; // Return existing data if no fetch needed
  }

  async setCreditCardArr() {
    try {
      const creditCardArr =
        this.commonService.getCreditCardInfoFromLocalStorage();
      const updatedArr = await this.handleCreditCardInfo(creditCardArr);
      this.creditCardInfo.set(updatedArr);
    } catch (error) {
      console.error('Error setting credit card info:', error);
      // Handle error appropriately, e.g., show an alert to the user
    }
  }

  getSummaryInfo(): void {
    if (this.planItem && this.planItem[0]?.amount == 0) {
      this.paymentSummary = {
        id: this.planItem[0].payments.filter(
          (payment: any) =>
            payment.paymentMethodType.id === PaymentTypeId.GRATUITO
        )[0].id,
        amount: 0,
        plan: this.planItem[0].id,
        planName: this.planItem[0]?.planName,
        qtyDependents: 0,
        paymentMethodType: {
          id: PaymentTypeId.GRATUITO,
          name: 'Gratuito',
        },
        period: {
          id: PaymentFrequencyId.MENSAL,
          description: 'MENSAL',
        },

        enableItems: [],
      };
    } else {
      if (
        !this.finalizePaymentformGroup.get('formOfPaymentControl')?.value ||
        !this.finalizePaymentformGroup.get('planControl')?.value ||
        !this.finalizePaymentformGroup.get('frequencyControl')?.value
      ) {
        return;
      }

      if (this.planItem[0].payments) {
        this.paymentMethodType = this.planItem[0]?.payments.filter(
          (payment: any) =>
            payment.paymentMethodType.id ===
            this.finalizePaymentformGroup.get('formOfPaymentControl')?.value
        );
      } else {
        this.paymentMethodType = this.planItem?.filter(
          (payment: any) =>
            payment.paymentMethodType.id ===
            this.finalizePaymentformGroup.get('formOfPaymentControl')?.value
        );
      }

      if (this.paymentMethodType.length > 1) {
        this.paymentMethodType = this.paymentMethodType.filter(
          (payment: any) =>
            payment.period.id ===
            this.finalizePaymentformGroup.get('frequencyControl')?.value
        );
      }

      if (this.paymentMethodType.length > 1) {
        this.setDependentsOptions();
      } else {
        this.setDependentsOptions();
        this.paymentSummary = this.paymentMethodType.reduce(
          (prev: any, current: any) => {
            return prev.qtdInstallments < current.qtdInstallments
              ? prev
              : current;
          }
        );
        if (
          this.paymentSummary?.enableItems?.length == 0 ||
          !this.paymentSummary?.enableItems
        ) {
          this.getBenefitsByPlanId(
            this.finalizePaymentformGroup.get('planControl')?.value as string
          );
        }
      }
    }
  }

  updateValueWithDependents(): void {
    if (this.finalizePaymentformGroup.get('dependentControl')?.value) {
      this.paymentSummary = this.paymentMethodType.filter(
        (payment: any) =>
          payment.qtyDependents ==
          this.finalizePaymentformGroup.get('dependentControl')?.value
      )[0];
      if (!this.paymentSummary) {
        this.paymentSummary = this.paymentMethodType.reduce(
          (prev: any, current: any) => {
            return prev.qtyDependents < current.qtyDependents ? prev : current;
          }
        );
        this.finalizePaymentformGroup
          .get('dependentControl')
          ?.setValue(this.paymentSummary.qtyDependents);
      }
      this.paymentSummary.planName = this.planItem[0]?.planName;

      if (!this.benefitsPlanSelected) {
        this.getBenefitsByPlanId(
          this.finalizePaymentformGroup.get('planControl')?.value as string
        );
      } else {
        this.paymentSummary.enableItems = this.benefitsPlanSelected;
      }
      if (!this.creditCardformGroup.get('numberOfInstallmentsControl')?.value) {
        this.creditCardformGroup
          .get('numberOfInstallmentsControl')
          ?.setValue('1');
      }
      this.updateValueWithInstallments();
    }
  }

  updateValueWithInstallments(): void {
    if (!this.finalizePaymentformGroup.get('dependentControl')?.value) {
      this.finalizePaymentformGroup.get('dependentControl')?.setValue('0');
    }
    if (this.creditCardformGroup.get('numberOfInstallmentsControl')?.value) {
      this.paymentSummary = this.paymentMethodType.filter(
        (payment: any) =>
          payment.qtdInstallments ==
            this.creditCardformGroup.get('numberOfInstallmentsControl')
              ?.value &&
          payment.qtyDependents ==
            this.finalizePaymentformGroup.get('dependentControl')?.value
      )[0];

      if (!this.paymentSummary) {
        this.paymentSummary = this.paymentMethodType
          .filter(
            (payment: any) =>
              payment.qtyDependents ==
              this.finalizePaymentformGroup.get('dependentControl')?.value
          )
          .reduce((prev: any, current: any) => {
            return prev.qtdInstallments < current.qtdInstallments
              ? prev
              : current;
          });

        this.paymentSummary.planName = this.planItem[0]?.planName;
      }

      if (!this.benefitsPlanSelected) {
        this.getBenefitsByPlanId(
          this.finalizePaymentformGroup.get('planControl')?.value as string
        );
      } else {
        this.paymentSummary.enableItems = this.benefitsPlanSelected;
      }
    }
  }

  getBenefitsByPlanId(id: string): void {
    this.planService.getPublicPlans().subscribe((plan: any) => {
      plan = plan.content.filter((benefit: any) => benefit.id === id)[0];
      this.paymentSummary.planName = this.planItem[0]?.planName;
      this.paymentSummary.enableItems = this.benefitsPlanSelected =
        plan?.benefits
          .filter((benefit: any) => benefit?.isActive)
          .map((benefit: any) => benefit?.benefitName) as string[];
    });
  }

  private setPixIconColor(value: string) {
    this.formOfPaymentOptions?.find((item) => {
      if (item.label === PaymentType.PIX) {
        let valueLabel = this.getPaymentFormLabel(value);
        if (valueLabel === PaymentType.PIX) {
          item.icon = 'pix-white';
        } else {
          item.icon = 'pix-darker';
        }
      }
    });
  }

  updateSummary(formOfPayment: PaymentType): void {
    if (!formOfPayment) return;

    let formOfPaymentLabel = this.getPaymentFormLabel(formOfPayment);
    let formOfPaymentId = formOfPayment as string;

    this.getSummaryInfo();

    if (
      (formOfPaymentId === PaymentTypeId.CREDITO ||
        formOfPaymentLabel === PaymentType.CREDITO_ABREVIADO ||
        formOfPaymentId === PaymentTypeId.CREDITO_RECORRENCIA) &&
      this.operation !== OperationType.UPGRADE_RECURRENCE
    ) {
      this.showCreditCardOptions = true;
      if (formOfPaymentId === PaymentTypeId.CREDITO_RECORRENCIA) {
        this.finalizePaymentformGroup
          .get('invoiceRecurrenceControl')
          ?.setValue(true);
        this.creditCardformGroup
          .get('numberOfInstallmentsControl')
          ?.setValue('1');
        this.creditCardformGroup.get('numberOfInstallmentsControl')?.disable();
        this.showCanUseCardControl = false;
      } else {
        this.finalizePaymentformGroup
          .get('invoiceRecurrenceControl')
          ?.setValue(false);
        this.creditCardformGroup
          .get('numberOfInstallmentsControl')
          ?.setValue('');
        this.creditCardformGroup.get('numberOfInstallmentsControl')?.enable();
        this.showCanUseCardControl = true;
      }
      this.setCreditFieldRequired();
    } else {
      this.showCreditCardOptions = false;
      this.creditCardformGroup.reset();
      this.clearCreditFieldValidators();
    }
  }

  setDependentsOptions(): void {
    this.finalizePaymentformGroup.get('dependentControl')?.setValue('0');
    this.dependentsOptions = [];
    let qtyDependents = this.paymentMethodType?.map(
      (payment: any) => payment.qtyDependents
    );
    qtyDependents = [...new Set(qtyDependents)];
    qtyDependents.sort((a: any, b: any) => a - b);
    qtyDependents.forEach((qty: any) => {
      this.dependentsOptions.push({
        code: qty.toString(),
        name: qty.toString() === '0' ? 'Sem Dependentes' : qty.toString(),
      });
    });
    if (
      this.dependentsOptions.length > 1 &&
      this.dependentsOptions.some((option) => option.code !== '0')
    ) {
      this.showDependentsOptions = true;
    }
    this.updateValueWithDependents();
  }

  save(): void {
    if (this.operation === OperationType.UPGRADE_RECURRENCE) {
      this.sendRecurrentUpgrade();
      return;
    }

    let paymentPayload: PaymentDataPayload | PostPublicMemberPlansModel;
    let paymentType = this.getPaymentFormLabel(
      this.finalizePaymentformGroup.get('formOfPaymentControl')?.value as string
    );

    if (paymentType === PaymentType.GRATUITO) {
      paymentPayload = {
        automaticRenewal: false,
        couponId: '',
        discount: 0,
        earlyRenewalOrigin: false,
        installments: 0,
        memberId: this.memberId,
        memberPlanStatus: {
          id: '12b5d978-a34a-43d6-abf6-8c99b11a1573',
          name: 'Ativo',
          description: 'Plano Ativo',
        },
        planId: this.finalizePaymentformGroup.get('planControl')?.value || '',
        planOriginId: '',
        planPaymentId: this.paymentSummary.id,
        qtyPoints: 0,
        recurrence: false,
        renewalOrigin: false,
        upgradedOrigin: false,
      };
      this.postFreePlan(paymentPayload as PostPublicMemberPlansModel);
    } else {
      const canUseCardValue =
        this.creditCardformGroup.get('canUseCardControl')?.value ?? false;

      paymentPayload = {
        cardCvv: this.creditCardformGroup.get('cvvControl')?.value || '',
        cardExpirationDate:
          this.creditCardformGroup
            .get('expirationDateControl')
            ?.value?.replace(/\/(\d{2})$/, '/20$1') || '',
        cardHolderName:
          this.creditCardformGroup.get('cardNameControl')?.value || '',
        cardNumber:
          this.creditCardformGroup.get('cardNumberControl')?.value || '',
        cpf: this.creditCardformGroup.get('CPFControl')?.value || '',
        installments: parseInt(
          this.creditCardformGroup.get('numberOfInstallmentsControl')?.value ||
            '1'
        ),
        memberId: this.memberId,
        paymentMethodId:
          this.finalizePaymentformGroup.get('formOfPaymentControl')?.value ||
          '',
        periodicityId:
          this.finalizePaymentformGroup.get('frequencyControl')?.value || '',
        planId: this.finalizePaymentformGroup.get('planControl')?.value || '',
        planPaymentId: this.paymentSummary.id,
        discount: this.paymentSummary.discount,
        operationType: this.getOperationType(this.operation),
        canUseCard: canUseCardValue,
      };

      this.postPaidPlan(
        paymentPayload as PaymentDataPayload,
        paymentType as PaymentType
      );
    }
  }

  sendRecurrentUpgrade(): void {
    const paymentData: RecurrentUpgradeTO = {
      memberId: this.memberId,
      planId: this.paymentSummary.plan,
      methodId:
        this.finalizePaymentformGroup.get('formOfPaymentControl')?.value || '',
      planPaymentId: this.paymentSummary.id,
      recurrence: true,
      amount: this.paymentSummary.amount,
      status: 'waiting_payment',
    };

    this.paymentDataService.sendRecurrentUpgrade(paymentData).subscribe(
      (data: RecurrentUpgradeTO) => {
        // Handle success, e.g., navigate to a success page
        this.router.navigate(['/finalizar-pagamento/seja-bem-vindo']);
      },
      (error) => {
        console.error('Recurrent upgrade failed', error);
        // Handle error, e.g., show an error message
        this.alertService.showAlert(
          AlertType.DANGER,
          'Erro ao processar Upgrade. Tente novamente mais tarde.'
        );
      }
    );
  }

  postFreePlan(paymentPayload: PostPublicMemberPlansModel): void {
    let freePaymentPayload: FreePaymentDataPayload;
    freePaymentPayload = {
      memberId: paymentPayload.memberId,
      planId: paymentPayload.planId,
      planPaymentId: paymentPayload.planPaymentId,
      methodId: 'ea737f0c-441b-46f6-a7d4-b139f8b16357',
    };
    this.memberService
      .postFreeMemberPlans(freePaymentPayload)
      .subscribe((data: any) => {
        this.paymentDataService.setOperationType(
          this.operation || OperationType.NEW
        );
        this.router.navigate(['/finalizar-pagamento/seja-bem-vindo']);
      });
  }

  postPaidPlan(
    paymentPayload: PaymentDataPayload,
    paymentType: PaymentType
  ): void {
    paymentPayload.deviceId = this.deviceId;
    this.paymentDataService.sendPaymentData(paymentPayload).subscribe(
      (data: any) => {
        if (data.code == 400) {
          this.alertService.showAlert(AlertType.DANGER, data.message);
          this.creditCardformGroup.reset();
        } else {
          if (
            paymentType === PaymentType.CREDITO ||
            paymentType === PaymentType.CREDITO_ABREVIADO
          ) {
            if (data.status === 'paid') {
              this.memberService.setMemberPlan(this.planItem);
              this.paymentDataService.setOperationType(
                this.operation || OperationType.NEW
              );

              const canUseCardValue =
                this.creditCardformGroup.get('canUseCardControl')?.value ??
                false;
              if (canUseCardValue) {
                this.memberService
                  .updateCanUseCard(this.memberId, canUseCardValue)
                  .subscribe(() => {
                    setTimeout(() => {
                      this.router.navigate([
                        '/finalizar-pagamento/seja-bem-vindo',
                      ]);
                    }, 2000);
                  });
              } else {
                setTimeout(() => {
                  this.router.navigate(['/finalizar-pagamento/seja-bem-vindo']);
                }, 2000);
              }
            }
          } else {
            this.paymentModalData = {
              invoiceNumer: data.id,
              invoiceCode: data.qrCodeData,
              invoiceCodeImg: data.qrCodeImageUrl,
            };
            this.paymentType = PaymentType.PIX;
            this.checkPixPayment(data.orderId);
            this.modalService.open(this.modalId);
          }
        }
      },
      (error) => {
        this.blockSubmit();
      }
    );
  }

  blockSubmit() {
    this.submitCount = 30;
    if (this.submitCount > 0) {
      const interval = setInterval(() => {
        this.submitCount--;
        if (this.submitCount === 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  getOperationType(operation: OperationType): string {
    switch (operation) {
      case OperationType.UPGRADE:
        return 'upgrade';
      case OperationType.RENEW:
        return 'renewal';
      case OperationType.EARLY_RENEW:
        return 'early_renewal';
      default:
        return 'subscription';
    }
  }

  checkPixPayment(orderId: any): void {
    this.paymentDataService.getPaymentOrder(orderId).subscribe((data) => {
      if (data.status != 'paid') {
        if (this.canChekPix) {
          setTimeout(() => {
            this.checkPixPayment(orderId);
          }, 3000);
        }
      } else {
        this.memberService.setMemberPlan(this.planItem);
        this.modalService.close(this.modalId);
        this.paymentDataService.setOperationType(
          this.operation || OperationType.NEW
        );
        this.router.navigate(['/finalizar-pagamento/seja-bem-vindo']);
      }
    });
  }

  private initializeForm(
    formGroup: FormGroup,
    paymentInfo: PaymentInfoModel
  ): void {
    if (Object.keys(paymentInfo).length > 0) {
      formGroup.get('planControl')?.setValue(paymentInfo?.plan);
      formGroup.get('frequencyControl')?.setValue(paymentInfo?.frequency);
      formGroup
        .get('invoiceRecurrenceControl')
        ?.setValue(paymentInfo?.invoiceRecurrence);
      formGroup
        .get('formOfPaymentControl')
        ?.setValue(paymentInfo?.formOfPayment);
      formGroup.get('dependentControl')?.setValue(paymentInfo?.dependents);
      if (
        paymentInfo?.formOfPayment === PaymentType.CREDITO ||
        paymentInfo?.formOfPayment === PaymentType.CREDITO_ABREVIADO ||
        paymentInfo?.formOfPayment === PaymentType.CREDITO_RECORRENCIA
      ) {
        formGroup.get('creditCardControl')?.setValue(paymentInfo?.creditCard);
        if (paymentInfo?.formOfPayment === PaymentType.CREDITO_RECORRENCIA) {
          formGroup.get('numberOfInstallmentsControl')?.setValue('1');
        } else {
          formGroup
            .get('numberOfInstallmentsControl')
            ?.setValue(paymentInfo?.numberOfInstallments);
        }
        this.setCreditFieldRequired();
      }
    }
  }

  private setCreditFieldRequired(): void {
    this.creditCardformGroup.controls.cardNumberControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.expirationDateControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.cvvControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.cardNameControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.CPFControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.numberOfInstallmentsControl.setValidators(
      Validators.required
    );
    this.creditCardformGroup.controls.cardNumberControl.updateValueAndValidity();
    this.creditCardformGroup.controls.expirationDateControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cvvControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cardNameControl.updateValueAndValidity();
    this.creditCardformGroup.controls.CPFControl.updateValueAndValidity();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.updateValueAndValidity();
  }

  private clearCreditFieldValidators(): void {
    this.creditCardformGroup.controls.cardNumberControl.clearValidators();
    this.creditCardformGroup.controls.expirationDateControl.clearValidators();
    this.creditCardformGroup.controls.cvvControl.clearValidators();
    this.creditCardformGroup.controls.cardNameControl.clearValidators();
    this.creditCardformGroup.controls.CPFControl.clearValidators();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.clearValidators();
    this.creditCardformGroup.controls.cardNumberControl.updateValueAndValidity();
    this.creditCardformGroup.controls.expirationDateControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cvvControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cardNameControl.updateValueAndValidity();
    this.creditCardformGroup.controls.CPFControl.updateValueAndValidity();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.updateValueAndValidity();
  }

  getPaymentFormLabel(formOfPaymentId: string): string {
    return (
      this.formOfPaymentOptions.find(
        (payment: any) => payment.value === formOfPaymentId
      )?.label || ''
    );
  }

  getFrequencyFormLabel(formOfFrequencyId: string): string {
    return (
      this.frequencyOptions.find(
        (payment: any) => payment.value === formOfFrequencyId
      )?.label || ''
    );
  }

  onModalConfirm(): void {}

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
    this.canChekPix = false;
  }

  back() {
    this.location.back();
  }
}

export enum OperationType {
  NEW = 0,
  UPGRADE = 1,
  DOWNGRADE = 2,
  NEWLOGGED = 3,
  RENEW = 4,
  EARLY_RENEW = 5,
  UPGRADE_RECURRENCE = 6,
  RECURRENCE = 7,
}
