@if(plan){
<div class="pt-4 pt-md-0 px-md-3 px-lg-0 pb-3">
  <div class="align-items-center d-flex justify-content-between my-4">
    <div class="primary-title">Meu Plano</div>
    <app-button
      id="back-desktop"
      [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="buttonOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>
  @if(showUpgradeCard && !isSecondaryPlan){
  <div class="py-2">
    <app-upgrade-card [plan]="plan"></app-upgrade-card>
  </div>
  }

  <div class="py-2">
    <app-my-plan-card
      [plan]="plan"
      [detailedMode]="true"
      [showDowngrade]="true"
    ></app-my-plan-card>
  </div>

  <!-- Visivel apenas no mobile -->
  @if( this.memberShipCard != null ){
  <div class="d-md-none col-12">
    <div class="my-5 d-grid carteirinha justify-content-center">
      <!-- <img class="img-fluid" [src]="carteirinhaImg" alt="Carteitinha"> -->
      <div class="memberShipCard w-auto" [ngStyle]="styles">
        <div class="row m-0">
          <div class="col-9 d-flex align-items-start flex-column mb-2">
            <div class="mb-auto p-2 d-flex">
              <img
                class="img-fluid imgCarteirinha mt-1"
                [src]="carteirinhaImg"
                alt="Carteitinha"
              />
              <h1 class="align-items-center mt-4" style="font-size: 1rem">
                N°: {{ memberShipCard?.cardNumber }}
              </h1>
            </div>
            <div class="p-2">
              <h1 class="align-items-end" style="font-size: 1.2rem">
                {{ memberShipCard?.member?.name }}
              </h1>
            </div>
          </div>
          <div class="col-3 card-lateral"></div>
        </div>
      </div>
      @if(this.member.memberSituation?.id == memberSituationEnum.Adimplente &&
      this.virtualCard == 'true'){
      <div
        id="show-carteirinha"
        class="p-2 my-3 text-center show-carteirinha cursor-pointer"
        (click)="showMembershipCard(memberShipCard)"
      >
        QrCode da Carteirinha<i class="bi bi-box-arrow-up-right"></i>
      </div>
      }
    </div>
  </div>
  }

  <div class="py-2" id="invoice-card" *ngIf="plan">
    <app-invoice-table
      [memberPlanId]="plan.id"
      [invoiceList]="invoiceList"
      [fullMode]="true"
      (invoiceTableResponse)="getMemberPlanDashboardData()"
    ></app-invoice-table>
  </div>

  @if (memberShipCardsDependents.length > 0) {
  <div class="secondary-title my-4">Carteirinhas dos Dependentes</div>
  }

  <div
    class="d-inline-flex flex-row mb-4"
    *ngFor="let dependetCard of memberShipCardsDependents"
  >
    <div class="m-2 d-grid carteirinha justify-content-end">
      <div class="memberShipCard" [ngStyle]="styles">
        <div class="row m-0">
          <div class="col-9 d-flex align-items-start flex-column mb-2">
            <div class="mb-auto p-2 d-flex">
              <img
                class="img-fluid imgCarteirinha mt-1"
                [src]="carteirinhaImg"
                alt="Carteitinha"
              />
              <h1 class="align-items-center mt-4" style="font-size: 1rem">
                N°: {{ dependetCard?.cardNumber }}
              </h1>
            </div>
            <div class="p-2">
              <h1 class="align-items-end" style="font-size: 1.2rem">
                {{ dependetCard?.memberDependent?.name }}
              </h1>
            </div>
          </div>
          <div class="col-3 card-lateral"></div>
        </div>
      </div>
      @if(this.member.memberSituation?.id == memberSituationEnum.Adimplente){
      <div
        id="show-carteirinha"
        class="p-2 my-3 text-center show-carteirinha cursor-pointer"
        (click)="showMembershipCard(dependetCard)"
      >
        QrCode da Carteirinha<i class="bi bi-box-arrow-up-right"></i>
      </div>
      }
    </div>
  </div>

  <!-- TODO descomentar quando tiver integrado -->
  <!-- <div class="secondary-title my-4">
        Carteirinhas vencidas
    </div>
    <div class="my-4 d-grid d-md-flex carteirinha">
        <div class="carteirinha me-md-3 mb-3">
            <img class="img-fluid" [src]="carteirinhaVenc1" alt="Carteitinha Vencida 1">
        </div>
        <div class="carteirinha">
            <img class="img-fluid" [src]="carteirinhaVenc2" alt="Carteitinha Vencida 2">
        </div>
    </div> -->
</div>
}

<app-membershipcard-modal
  modalId="membershipCardModalDependents"
  [memberShipCard]="memberShipCard"
  (modalClose)="modalService.close('membershipCardModal')"
></app-membershipcard-modal>

<!-- <app-view-plan-card [trigger]="trigger$" [memberShipCard]="memberShipCard"></app-view-plan-card> -->
