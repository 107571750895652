<button mat-icon-button aria-label="Opções de menu" type="button" data-bs-toggle="offcanvas"
  data-bs-target="#userSideBar" aria-controls="userSideBar" [ngStyle]="{ color: inputStyles['--menu-color-item'] }">
  <mat-icon>menu</mat-icon>
</button>


<div class="offcanvas offcanvas-end user-side-bar" data-bs-backdrop="static" data-bs-scroll="true" tabindex="-1"
  id="userSideBar" aria-labelledby="userSideBarLabel" [ngStyle]="inputStyles">
  <div class="offcanvas-header">
    @if (isLoggedIn()) {
    <div class="d-flex">
      <!-- <div class="user-img">
        <img [src]="userPhoto" alt="User image">
      </div> -->
      <div class="d-grid align-content-around">
        <div class="mx-2 user-name">
          {{userInfo().name}}
        </div>
        @if (userRank) {
          <div class="mx-2 user-score">
            Rank: {{userRank}} <i class="bi bi-dot"></i>
            <span>{{userScore}} pontos</span>
          </div>
        } @else {
          <div class="mx-2 user-score">
            Ainda não tem Ranking
          </div>
        }
      </div>
    </div>
    } @else {
    <div class="header-text">
      <p class="line1">
        <span class="prefix">{{ logoStyles.prefixText }}</span>
        <span class="highlight">{{ logoStyles.highlightedText }}</span>
      </p>
      <p class="sufix">{{ logoStyles.suffixText }}</p>
    </div>
    }
    <button id="btn-offcanvas-close" type="button" class="btn" data-bs-dismiss="offcanvas" aria-label="Close" [ngStyle]="{ 
            color: inputStyles['--menu-color-item'], 
            position: 'absolute', 
            top: '0px', 
            right: '0px' 
        }">
      <i class="bi bi-x" style="font-size: 2rem;"></i>
    </button>


  </div>
  <div class="offcanvas-body">
    @for (menuItem of menuItems(); track $index) {
      @if(activeSections[menuItem?.id]){
        <div class="cursor-pointer mb-4 mt-3" [class.active]="menuItem.id === selectedMenuItem()"
          (click)="navigationHeaderItems(menuItem.id)">
          <i *ngIf="menuItem.id === selectedMenuItem()" class="bi bi-star-fill star-menu-icon"></i>
          <div class="menu-item-name cursor-pointer" [class.disabled]="isLoggedIn() && userBlocked()"
            [ngClass]="{'selected': menuItem.id === selectedMenuItem()}">
            {{ menuItem.name }}
          </div>
        </div>
      }
    }

    <div class="fc-logo py-1 justify-content-center d-flex">
      <img [src]="logoStyles.url" alt="Logo" class="logo">
    </div>

    @if (isLoggedIn()) {
    <div class="mt-4">
      <app-user-actions [color]="actionsSideColor" (actionsEvent)="closeOffcanvas()"
        [userBlocked]="userBlocked()"></app-user-actions>
    </div>
    } @else {
    <div class="d-grid login-sec-side-bar py-4">
      <div class="text-center menu-item-name">Ainda não possui uma conta?</div>
      <app-button class="justify-self-center my-3 w-100" id="cadastro-socio"
        [buttonOptions]="buttonGonnaBePartnerOptions" [style]="buttonStyles?.buttonSocio" (click)="closeOffcanvas()"
        routerLink="/cadastro-socio">
      </app-button>
      <hr>
      <div class="text-center menu-item-name">Já possui uma conta?</div>
      <app-button class="justify-self-center my-3 w-100" id="header-login" [buttonOptions]="buttonLoginOptions"
        [style]="buttonStyles?.buttonEntrar" (click)="closeOffcanvas()" routerLink="/login">
      </app-button>
    </div>
    }
  </div>
</div>