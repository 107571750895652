<div class="pt-4 px-md-3 px-lg-0" [ngStyle]="styles">
  <div class="d-flex justify-content-sm-between">
    <div class="partner-data-main-title mt-1 mb-5" [style.color]="labelColor">
      Ranking de Sócios
    </div>
    <app-button
      id="back-desktop"
      [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="backOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>

  <ng-container *ngIf="user.participatesInRanking; else notParticipating">
    <div *ngIf="memberFinalScore; else noRanking">
      <div class="row mb-3">
        <div class="col-6 col-md-3 mb-3" *ngFor="let info of infos">
          <app-info-card
            [title]="info.title"
            [value]="info.value"
            [icon]="info.icon"
            [extraText]="info.extraText"
          ></app-info-card>
        </div>
      </div>
      <div class="row mb-4 d-flex flex-column flex-md-row">
        <div class="col">
          <div class="card h-100">
            <div class="card-body card-estatistica">
              <h5 class="h5 mb-3 hl">Suas Estatísticas</h5>
              <!-- <p class="h6">Melhor posição no ranking: <span class="hl h4">{{ memberFinalScore.bestPosition }}º</span></p>
                        <p class="h6">Máximo de pontos acumulado: <span class="hl h4">{{ memberFinalScore.maxPoints }} pontos</span></p> -->
              <p class="h6">
                Experiências resgatadas:
                <span class="hl h4">{{
                  memberFinalScore.totalExperiences
                }}</span>
              </p>
              <p class="h6">
                Produtos resgatados:
                <span class="hl h4">{{ memberFinalScore.totalProducts }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body card-historico">
              <h2 class="h5 hl">Histórico de pontos</h2>
              @for(history of historyPoints.slice(0,3); track $index){
              <p class="data mb-2">
                {{ history.createdAt | date : "dd/MM/YYYY" : "+0000" }}
              </p>
              <div class="">
                <div class="d-flex justify-content-between">
                  <span class="h6 m-0">{{ history?.description }}</span>
                  <span class="color-primary"
                    >{{ history?.points }} pontos</span
                  >
                </div>
                <hr class="hr" />
              </div>
              }
              <div class="d-flex justify-content-center">
                <app-button
                  (buttonClick)="goToHistoryPoints()"
                  [buttonOptions]="buttonOptions"
                ></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        class="section-ranking"
        [ngStyle]="styles"
        *ngIf="ranking?.displaySection"
      >
        <app-ranking-table
          [tableConfig]="tableConfig"
          *ngIf="tableConfig"
        ></app-ranking-table>
      </section>
    </div>
  </ng-container>
  <ng-template #noRanking>
    <div class="col-12 text-center py-1">
      <b>Você ainda não possui pontos para aparecer no ranking</b>
    </div>
  </ng-template>
  <ng-template #notParticipating>
    <div class="col-12 text-center py-1">
      <b>Você ainda não participa do Ranking</b>
    </div>
  </ng-template>
</div>
