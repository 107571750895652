<div class="mt-2 position-relative">
  <div class="d-flex justify-content-end back-buttom" *ngIf="!selectPlan">
    <app-button
      id="back-desktop"
      [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="buttonBackOptions"
      (buttonClick)="back()"
      [onlyIcon]="true"
    >
    </app-button>
  </div>
  <section class="section-upgrade-planos" *ngIf="showPlans">
    <div class="container wrapper-upgrade-planos">
      <div class="wrapper-upgrade-planos pb-3" *ngIf="items.length > 0">
        <!-- <app-logo-text class="mb-4"></app-logo-text> -->
        <p class="subtitle-upgrade-planos">
          Selecione o plano para fazer {{ getOperationTypeLabel() }}
        </p>
      </div>
      <ng-container *ngIf="items.length > 0; else noPlans">
        <app-carousel-plans
          [items]="items"
          [styles]="styles"
          [defaultButtonOptions]="buttonOptionsCard"
          [highlightButtonOptions]="buttonOptionsCardSpecial"
          (carouselResponse)="carouselResponse($event)"
        ></app-carousel-plans>
      </ng-container>
      <ng-template #noPlans>
        <div class="col-12 text-center py-5">
          <b>{{ getNoPlansMessage() }}</b>
        </div>
      </ng-template>
    </div>
  </section>
</div>

<app-modal
  [modalTitle]="'Pagamento em Aberto'"
  [modalId]="orderModalId"
  [modalBodyMessage]="orderModalBodyMessage"
  [showCloseBtn]="true"
  (confirmActionChange)="onOrderModalConfirm($event)"
>
</app-modal>

@if (showModalNotRenewed) {
<app-modal
  [modalTitle]="selectOperationTypeModal.title"
  [modalId]="selectOperationTypeModal.id"
  [modalBodyMessage]="selectOperationTypeModal.bodyMessage"
  [showCloseBtn]="selectOperationTypeModal.showCloseBtn"
  [showThirdBtn]="selectOperationTypeModal.showThirdBtn"
  [modalConfirmLabel]="selectOperationTypeModal.confirmLabel"
  [modalCancelLabel]="selectOperationTypeModal.cancelLabel"
  [modalThirdLabel]="selectOperationTypeModal.thirdLabel"
  [backdropType]="selectOperationTypeModal.backdropType"
  [size]="selectOperationTypeModal.size"
  (confirmActionChange)="onSelectOperationTypeModalConfirm($event)"
  (thirdActionChange)="onSelectOperationTypeModalThird($event)"
>
</app-modal>
}

<app-payment-modal
  [modalId]="modalId"
  [paymentType]="paymentType"
  [paymentModalData]="paymentInvoiceModalData"
  (confirmActionChange)="onModalConfirm()"
>
</app-payment-modal>
