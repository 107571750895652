import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppButtonComponent } from '../../shared/components/app-button/app-button.component';
import { MemberService } from '../../core/services/member.service';
import { UserService } from '../../core/services/user.service';
import { UserModel } from '../../shared/models/userModel';
import { Location } from '@angular/common';
import { PlanDashboardTO } from 'src/app/shared/models/planModel';
import { InvoiceModelPublic } from 'src/app/shared/models/invoiceModel';
import { InvoiceTableComponent } from 'src/app/shared/components/invoice-table/invoice-table.component';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-outros-planos',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, InvoiceTableComponent],
  templateUrl: './outros-planos.component.html',
  styleUrls: ['./outros-planos.component.scss'],
})
export class OutrosPlanosComponent implements OnInit {
  memberPlans: any[] = [];
  member: UserModel;
  plan: PlanDashboardTO;
  invoiceList: InvoiceModelPublic[];
  pageSize = 15;

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  constructor(
    private memberService: MemberService,
    private userService: UserService,
    private location: Location,
    private alertService: AlertService
  ) {
    this.member = this.userService.currentUserValue as UserModel;
  }

  ngOnInit() {
    this.memberService.getMemberPlanDashboardData(this.member.id).subscribe({
      next: (res) => {
        this.plan = res;
        if (this.plan) {
          this.memberService
            .getMembeInvoiceDashboardData(this.plan.id)
            .subscribe((invoices) => {
              invoices.forEach((invoice) => {
                invoice.referenceMonthSort = new Date(
                  parseInt(invoice.refYear),
                  parseInt(invoice.refMonth) - 1
                );
                invoice.refMonth = invoice.refMonth
                  ?.toString()
                  ?.padStart(2, '0');
              });
              invoices.sort(
                (a, b) =>
                  new Date(b.referenceMonthSort).getTime() -
                  new Date(a.referenceMonthSort).getTime()
              );
              this.invoiceList = invoices;
            });
        }
      },
      error: (e) => {
        console.error('Error:', e);
        this.alertService.showAlert(AlertType.WARNING, e.error.message);
      },
    });
  }

  back() {
    this.location.back();
  }
}
