<!-- <div class="row">
    <div class="col-md-3 mb-2">
        <app-input
            [id]="'searchInputId'"
            [label]="'Pesquisar'"
            [type]="InputTypeEnum.TEXT"
            formControlName="searchControl">
        </app-input>
    </div>
</div> -->
<div
  class="table-responsive custom-scrollbar px-0"
  *ngIf="tableConfig && rows.length > 0"
>
  <table class="custom-table table-striped">
    <thead [style]="tableConfig.thead">
      <tr>
        <th class="text-center">Ranking</th>
        <th class="text-left d-none d-md-table-cell">Sócio</th>
        <!-- <th class="text-center d-none d-md-table-cell">Cidade</th> -->
        <th class="text-center d-none d-md-table-cell">Pontos</th>
        <th class="text-center d-none d-md-table-cell">Tendência</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of rows; let i = index"
        [style]="
          i % 2 === 0
            ? tableConfig.primaryRowBackgroundColor
            : tableConfig.secondaryRowBackgroundColor
        "
      >
        <!-- Desktop view -->
        <td class="text-center d-none d-md-table-cell">
          {{ row?.currentPosition }}
        </td>
        <td class="text-left d-none d-md-table-cell">
          <div class="d-flex align-items-center">
            <!-- <img src="{{ row.photo }}" alt="Profile Photo" style="width: 28px; height: 28px; border-radius: 50%;"> -->
            <span class="ms-2">{{ row?.memberName }}</span>
          </div>
        </td>
        <!-- <td class="text-center d-none d-md-table-cell">
          {{ row?.member?.addresses[0]?.cityName }}
        </td> -->
        <td class="text-center d-none d-md-table-cell">
          {{ row.totalPoints }}
        </td>
        <td class="text-center d-none d-md-table-cell">
          <i
            class="bi bi-caret-{{ row.trend === 'up' ? 'up' : 'down' }}-fill"
            [style]="
              row.trend === 'up' ? tableConfig.caretUp : tableConfig.caretDown
            "
          ></i>
        </td>

        <!-- Mobile view -->
        <!-- <td class="d-md-none">
          <div class="d-flex flex-row align-items-center">
            <h6 class="h6 m-0 px-2">{{ row.ranking }}</h6>
            <img
              src="{{ row.photo }}"
              alt="Profile Photo"
              style="width: 28px; height: 28px; border-radius: 50%"
            />
            <div class="d-flex flex-column px-2 socio">
              <span class="socio-mb m-0">{{ row.socio }}</span>
              <span class="cidade-mb">{{ row.cidade }}</span>
            </div>
            <i
              class="px-2 bi bi-caret-{{
                row.trend === 'up' ? 'up' : 'down'
              }}-fill "
            ></i>
            <div class="text-center ml-auto socio-mb">
              {{ row.pontos }} pontos
            </div>
          </div>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>
