export interface UserModel {
  addresses?: AddressModel[];
  birthDate: string;
  cep: string;
  city: City;
  complement: string;
  confirmPassword: string;
  cpf: string;
  createdAt: string;
  description: string;
  district: string;
  emails?: EmailModel[];
  gender: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
  number: string;
  password: string;
  phones?: PhoneModel[];
  state: State;
  updatedAt: string;
  memberPlans?: MemberPlan[];
  orders?: any[];
  termsAccepted?: boolean;
  memberSituation?: MemberSituation;
  participatesInRanking?: boolean;
}

interface MemberSituation {
  id: string;
  name: string;
}

export interface MemberPlan {
  id: string;
  memberId: string;
  planName: string;
  planId: string;
  planOriginId: string;
  dateStart: string;
  dateEnd: string;
  memberPlanStatusId?: string;
  isSecondaryPlan: boolean;
}

interface City {
  id: number;
  name: string;
  state: State;
}

interface State {
  acronym: string;
  id: number;
  name: string;
}

interface EmailModel {
  email: string;
  description?: string;
  isPrincipal: boolean;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
}

interface PhoneModel {
  description?: string;
  isPrincipal: boolean;
  ddd?: string;
  number: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
}

interface AddressModel {
  zipCode: string;
  area: string;
  number: string;
  complement?: string;
  neighborhood: string;
  stateCode: number;
  cityCode: number;
  cityName: string;
  stateName: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export enum MemberPlanStatusId {
  Cancelado = '0c5a0147-fe7b-4a4b-86e5-322df3cc72aa',
  Vigente = '1dc1bc30-b0fd-45de-bd3c-72f78cafb8f4',
  Encerrado = '56fc318c-6836-4756-bf0b-d5023ae79788',
}
