import { Component, input, OnInit } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { PlanDashboardTO } from '../../models/planModel';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import {
  MemberService,
  MemberSituationEnum,
} from 'src/app/core/services/member.service';
import {
  MemberPlan,
  MemberPlanStatusId,
  UserModel,
} from '../../models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';
import { OperationType } from 'src/app/features/finalizar-pagamento/finalizar-pagamento.component';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { MembershipCardModalComponent } from '../membershipcard-modal/membershipcard-modal.component';
import { InvoiceModelPublic } from '../../models/invoiceModel';
import { SelectModel } from '../app-select-input/app-select-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { FuturePlansModalComponent } from '../future-plans-modal/future-plans-modal.component';

@Component({
  selector: 'app-my-plan-card',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RouterLink,
    ReactiveFormsModule,
    CustomCurrencyPipe,
    MembershipCardModalComponent,
    FuturePlansModalComponent,
  ],
  templateUrl: './my-plan-card.component.html',
  styleUrls: ['./my-plan-card.component.scss'],
})
export class MyPlanCardComponent implements OnInit {
  plan = input.required<PlanDashboardTO>();
  invoiceList = input<InvoiceModelPublic[]>();
  memberShipCard: any;
  detailedMode = input<boolean>(false);
  showDowngrade = input<boolean>(false);

  public styles: { [key: string]: string };

  carteirinhaImg = localStorage.getItem('imageLogo');

  docsOptions = {
    buttonText: 'Enviar documentação',
    buttonSize: 'btn btn-md px-3 py-1 float-right my-2 btn-primary',
    borderRadius: '25px',
  };

  btnPlanDetailsOptions = {
    buttonText: 'Ver detalhes do plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnRenewPlanOptions = {
    buttonText: 'Renovação',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEarlyRenewPlanOptions = {
    buttonText: 'Renovação Antecipada',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEditPlanOptions = {
    buttonText: 'Editar Plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnOtherPlansOptions = {
    buttonText: 'Outros planos',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEnvDocumentsOptions = {
    buttonText: 'Enviar Documentos',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };
  userInfo: UserModel;
  memberPlanList: SelectModel[] = [];
  currentPlan!: any;
  memberSituations: any[] = [];
  memberSituation: any;
  memberCanceled: boolean = false;
  notRenwed: boolean = false;
  hasPlansecondary: boolean = false;
  hasFuturePlans: boolean = false;
  isPlanSecondary: boolean = localStorage.getItem('isSecondaryPlan') === 'true';
  selectedPlan: FormControl = new FormControl();
  virtualCard = localStorage.getItem('virtualCard');
  hasVitoria = window.location.href.toLowerCase().includes('vitoria');

  constructor(
    private memberService: MemberService,
    private modalService: ModalService,
    private userService: UserService,
    private router: Router,
    private paymentDataService: PaymentDataService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };
    this.getMemberShipCardDashboardData();
    this.getCurrentUser();
  }

  getMemberShipCardDashboardData() {
    this.memberService
      .getMemberShipCardDashboardData(this.plan().member.id)
      .subscribe((res) => {
        this.memberShipCard = res;
      });
  }

  getCurrentUser() {
    this.userService.currentUser.subscribe((user) => {
      this.userInfo = user as UserModel;
      if (
        this.userInfo?.memberPlans &&
        this.userInfo?.memberPlans?.length > 0
      ) {
        this.memberPlanList = this.userInfo.memberPlans
          .filter(
            (plan) =>
              plan.memberPlanStatusId === MemberPlanStatusId.Vigente &&
              new Date(plan.dateStart) <= new Date()
          )
          .map((plan) => {
            return {
              code: plan.planId,
              name: `${
                plan.isSecondaryPlan
                  ? plan.planName + '(Secundário)'
                  : plan.planName
              }`,
            };
          });
        this.memberPlanList = this.memberPlanList.filter(
          (plan, index, self) =>
            index === self.findIndex((p) => p.code === plan.code)
        );
      }
      const currentDate = new Date();
      this.hasFuturePlans =
        this.userInfo?.memberPlans?.some(
          (plan: MemberPlan) => new Date(plan.dateStart) > currentDate
        ) || false;
      this.hasPlansecondary = this.userInfo?.memberPlans?.find(
        (plan) => plan.isSecondaryPlan
      )
        ? true
        : false;
      this.memberCanceled = this.userInfo?.memberSituation?.name == 'Cancelado';
      this.notRenwed =
        this.userInfo?.memberSituation?.name == 'Não Renovado' ||
        this.userInfo?.memberSituation?.name == 'Adimplente';
    });
    if (
      this.hasPlansecondary &&
      this.memberPlanList.length == 1 &&
      !this.isPlanSecondary
    ) {
      this.getSecondaryMemberPlanDetail();
    } else {
      if (this.userInfo) {
        if (localStorage.getItem('secondaryPlanDetail')) {
          this.currentPlan = JSON.parse(
            localStorage.getItem('secondaryPlanDetail') as string
          );
          this.selectedPlan.setValue(this.currentPlan?.planId);
        } else {
          this.getCurrentPlan();
        }
      }
    }

    this.onGetMemberSituations();
  }

  getCurrentPlan() {
    this.memberService
      .getCurrentPlan(this.userInfo.id)
      .subscribe((currentPlan: any) => {
        this.currentPlan = currentPlan;
        this.selectedPlan.setValue(this.currentPlan?.planId);
      });
  }

  setPlanSelected(event: any) {
    this.isPlanSecondary =
      this.userInfo?.memberPlans?.find(
        (plan) => plan.planId === event.target.value
      )?.isSecondaryPlan ?? false;

    if (this.isPlanSecondary) {
      this.getSecondaryMemberPlanDetail();
    } else {
      localStorage.removeItem('isSecondaryPlan');
      localStorage.removeItem('secondaryPlanDetail');
      localStorage.removeItem('userIdSecondaryPlan');
      window.location.reload();
    }
  }

  getSecondaryMemberPlanDetail() {
    this.memberService
      .getCurrentSecondaryPlanDetail(this.userInfo.id)
      .subscribe(
        (res: any) => {
          localStorage.setItem('isSecondaryPlan', 'true');
          localStorage.setItem('secondaryPlanDetail', JSON.stringify(res));
          localStorage.setItem('userIdSecondaryPlan', this.userInfo.id);
          window.location.reload();
        },
        (error: any) => {
          console.log('Error: ', error);
          this.alertService.showAlert(
            AlertType.DANGER,
            'Erro ao carregar plano'
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      );
  }

  onGetMemberSituations() {
    this.memberService.getMemberSituations().subscribe({
      next: (response) => {
        this.memberSituations = response.content;
      },
    });
  }

  setHeightCard(height: any) {
    const planCard = document.getElementById('planCard');
    if (planCard) {
      planCard.style.height = `${height}px`;
    }
  }

  showCarteirinha() {
    // this.trigger$.next(true);
    this.modalService.open('membershipCardModal');
  }

  checkDaysEndData() {
    let planEndDate = new Date(this.currentPlan?.planEndDate);
    let currentDate = new Date();
    let diff = Math.abs(planEndDate.getTime() - currentDate.getTime());
    let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  checkMemberSituation(buttomAction: string) {
    let adimplente = this.memberSituations.find(
      (situation: any) => situation.name == 'Adimplente'
    )?.id;
    let inadimplente = this.memberSituations.find(
      (situation: any) => situation.name == 'Inadimplente'
    )?.id;
    let mensalPlan = this.currentPlan?.periodicity == 'MENSAL';
    let acceptedRenewal = this.currentPlan?.ordinaryRenewal;
    let acceptedAnticipationRenewal = this.currentPlan?.anticipationRenewal;
    this.memberSituation = this.userInfo?.memberSituation?.id;

    const enableRenewalButton =
      localStorage.getItem('enableRenewalButton') === 'true';
    const enableAnticipationRenewal =
      localStorage.getItem('enableAnticipationRenewal') === 'true';

    const assinaturaInvoiceTypeId = '8aa5127e-92e6-4dd5-8563-d269d1a9ed4e';
    const pagoInvoiceStatusId = '7859ffd3-9390-4d25-89a6-8f27533bb37e';

    const invoiceListValue = this.invoiceList();
    const hasUnpaidAssinaturaInvoice = invoiceListValue?.some(
      (invoice: InvoiceModelPublic) =>
        invoice.invoiceType?.id === assinaturaInvoiceTypeId &&
        invoice.invoicePaymentStatus?.id !== pagoInvoiceStatusId
    );

    switch (buttomAction) {
      case 'cancel':
        if (
          this.memberSituation == adimplente ||
          this.memberSituation == inadimplente ||
          !this.memberCanceled
        )
          return true;
        return false;

      case 'upgrade':
        if (this.memberSituation == adimplente) return true;
        return false;

      case 'renew':
        if (!acceptedRenewal) return false;
        if (this.memberCanceled) return false;
        if (!this.notRenwed) return false;
        if (this.memberSituation != adimplente) return false;
        if (!enableRenewalButton) return false;
        if (hasUnpaidAssinaturaInvoice) return false;
        else {
          let daysEndData = this.checkDaysEndData();
          if (daysEndData > 30) {
            return false;
          }
          if (daysEndData <= 30) return true;
        }
        return true;

      case 'early_renew':
        if (!acceptedRenewal) return false;
        if (!acceptedAnticipationRenewal) return false;
        if (mensalPlan) return false;
        if (this.memberCanceled) return false;
        let daysEndDataEarly = this.checkDaysEndData();
        if (daysEndDataEarly <= 30) return false;
        if (this.memberSituation != adimplente) return false;
        if (!enableAnticipationRenewal) return false;
        return true;

      default:
        return false;
    }
  }

  goToRenewPlan(operationType: OperationType) {
    this.paymentDataService.setOperationType(operationType);
    this.router.navigate(['/renovar-plano']);
  }

  get operationType(): any {
    return OperationType;
  }

  get memberSituationEnum() {
    return MemberSituationEnum;
  }
}
