import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertSubject = new Subject<AlertInfo>();
  alertState = this.alertSubject.asObservable();

  constructor() { }

  showAlert(type: AlertType, message: string) {
    this.alertSubject.next({ type, message });
  }
}

export interface AlertInfo {
  type: AlertType;
  message: string;
}
export enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger'
}