import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Provider,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';

const COUNTRY_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppInputCustomComponent),
  multi: true,
};

@Component({
  selector: 'app-input-custom',
  standalone: true,
  imports: [
    CommonModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    ValidationErrorsComponent,
  ],
  providers: [COUNTRY_CONTROL_VALUE_ACCESSOR, provideNgxMask()],
  templateUrl: './app-input-custom.component.html',
  styleUrls: ['./app-input-custom.component.scss'],
})
export class AppInputCustomComponent<T>
  extends ControlValueAccessorDirective<T>
  implements OnChanges
{
  @Input() id = 'inputCustomId';
  @Input() label = 'Label';
  @Input() placeholder = '';
  @Input() mask: InputMaskTypes;
  @Input() dropSpecialCharacters: boolean = true;
  @Output() blur = new EventEmitter<FocusEvent>();

  onBlur(event: FocusEvent) {
    this.blur.emit(event);
  }


  customErrorMessages: Record<string, string> = {};

  // TODO: Rever estrutura de color
  labelColor = 'white';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mask']) {
      this.defineErrorMessage();
    }
  }

  defineErrorMessage(): void {
    switch (this.mask) {
      case InputMaskTypes.PHONE:
        this.customErrorMessages = {
          mask: 'Celular inválido',
          pattern: 'Celular inválido',
        };
        break;
      case InputMaskTypes.CEP:
        this.customErrorMessages = { mask: 'CEP inválido' };
        break;
      case InputMaskTypes.CREDIT_CARD:
        this.customErrorMessages = { mask: 'Número do cartão inválido' };
        break;
      case InputMaskTypes.MES_ANO:
        this.customErrorMessages = { mask: 'Número do mês ou do ano inválido' };
        break;
      default:
        break;
    }
  }
}

export enum InputMaskTypes {
  CPF = '000.000.000-00',
  PHONE = '(00) 00000-0000',
  CEP = '00000-000',
  CREDIT_CARD = '0000 0000 0000 0099',
  MES_ANO = '00/00',
  DATA = '00/00/0000'
}
