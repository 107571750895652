<div
  class="modal fade align-content-center payment-modal"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  [attr.data-bs-backdrop]="backdropType()"
>
  <div class="modal-dialog">
    <div *ngIf="paymentModalData()" class="modal-content">
      <div class="modal-header" *ngIf="paymentModalData().invoiceNumber">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          Pagar Fatura Nº {{ paymentModalData().invoiceNumber }}
        </h1>
        @if(paymentType() == PaymentType.PIX){
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="onCancel()"
        ></button>
        }
      </div>
      <div class="modal-body">
        <container-element [ngSwitch]="paymentType()">
          <ng-container *ngSwitchCase="PaymentType.CREDITO">
            <ng-container
              *ngTemplateOutlet="
                creditTemplate;
                context: { type: 'credito', data: paymentModalData() }
              "
            ></ng-container>
          </ng-container>
          <!-- <ng-container *ngSwitchCase="PaymentType.BOLETO">
              <ng-container *ngTemplateOutlet="pixAndBoletoTemplate;
                context: { type: 'boleto', label: 'Código de barras copia e cola', data: paymentModalData() }"></ng-container>
            </ng-container> -->

          <ng-container *ngSwitchCase="PaymentType.PIX">
            <ng-container
              *ngTemplateOutlet="
                pixAndBoletoTemplate;
                context: {
                  type: 'pix',
                  label: 'PIX Copia e Cola',
                  data: paymentModalData()
                }
              "
            ></ng-container>
          </ng-container>
        </container-element>
      </div>
      @if (paymentType() === PaymentType.CREDITO) {
      <div class="modal-footer">
        <app-button
          class="mx-2 my-1"
          id="cancelModalBtn"
          [buttonOptions]="cancelBtn"
          (buttonClick)="onCancel()"
        ></app-button>
        <app-button
          class="mx-2 my-1"
          id="confirmModalBtn"
          [buttonOptions]="confirmBtn"
          (buttonClick)="onConfirm()"
          [disabled]="!creditCardformGroup.valid"
        ></app-button>
      </div>
      }
    </div>
  </div>
</div>

<ng-template #creditTemplate let-type="type" let-data="data">
  <div class="mb-3">
    <form [formGroup]="creditCardformGroup">
      <div class="row">
        <app-input-custom
          class="col-12 col-md-6"
          [id]="'cardNumberInputId'"
          [placeholder]="'Insira o número do cartão'"
          [label]="'Número do cartão'"
          [mask]="InputMaskTypesEnum.CREDIT_CARD"
          formControlName="cardNumber"
        ></app-input-custom>
        <app-input-custom
          class="col-md-6"
          [id]="'expirationDateInputId'"
          [placeholder]="'MM/AA'"
          [label]="'Data de validade'"
          [mask]="InputMaskTypesEnum.MES_ANO"
          [dropSpecialCharacters]="false"
          formControlName="cardExpirationDate"
        ></app-input-custom>
      </div>
      <div class="row">
        <app-input
          class="col-md-6"
          [id]="'cvvInputId'"
          [label]="'CVV'"
          [placeholder]="'CVV'"
          [type]="InputTypeEnum.NUMBER"
          [maxLength]="3"
          [minLength]="3"
          formControlName="cardCvv"
        ></app-input>
      </div>
      <div class="row">
        <app-input
          class="col"
          [id]="'cardNameInputId'"
          [label]="'Nome do cartão'"
          [placeholder]="'Insira o nome do titular do cartão'"
          formControlName="cardHolderName"
        ></app-input>
      </div>
    </form>
  </div>
</ng-template>

<ng-template
  #pixAndBoletoTemplate
  let-type="type"
  let-label="label"
  let-data="data"
>
  <div>
    <app-input-copy-to-clipboard
      id="{{ type }}-input"
      [label]="label"
      [inputValue]="data.invoiceCode"
    ></app-input-copy-to-clipboard>
    <div class="code-img-modal-{{ type }} text-center p-3">
      <img class="img-fluid" [src]="data.invoiceCodeImg" alt="Código" />
    </div>
    <!-- Mensagem específica para PIX -->
    <div *ngIf="type === 'pix'" class="text-center mt-3">
      <p>
        A confirmação após o pagamento pode levar até dois minutos. <br />
        Assim que pagar, por gentileza aguardar!
      </p>
    </div>
  </div>
</ng-template>
