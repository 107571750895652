<div class="pt-4 pt-md-0 px-md-3 px-lg-0 pb-3">
  <div class="align-items-center d-flex justify-content-between my-4">
    <div class="primary-title">Outros Planos</div>
    <app-button
      id="back-desktop"
      [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="buttonOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>
  @if (plan) {
  <div class="card card-body">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Nome do Plano: </b> <span>{{ plan.plan.planName }}</span>
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Situação: </b> <span>{{ plan.isActive ? "Vigente" : "Não" }}</span>
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Data de Início: </b>
          <span>{{ plan.dateStart | date : "dd/MM/yyyy" : "+0000" }}</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Validade do Plano: </b>
          <span>{{ plan.dateEnd | date : "dd/MM/yyyy" : "+0000" }}</span>
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Valor do Plano: </b>
          <span>{{ plan.planPaymentAmount | currency : "BRL" }}</span>
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Renovação Automática: </b>
          <span>{{ plan.automaticRenewal ? "Sim" : "Não" }}</span>
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Periodicidade: </b> <span>{{ "-------" }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="py-2" id="invoice-card">
    <app-invoice-table
      [memberPlanId]="plan.id"
      [invoiceList]="invoiceList"
      [fullMode]="true"
      [pageSize]="pageSize"
    ></app-invoice-table>
  </div>
  }
</div>
