import { Directive, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[appControlValueAccessor]',
  standalone: true
})
export class ControlValueAccessorDirective<T> implements ControlValueAccessor, OnInit, OnDestroy {
  control: FormControl;
  isRequired = false;

  private _isDisabled = false;
  private _destroy$ = new Subject<void>();
  private _onTouched!: () => T;

  constructor(@Inject(Injector) private injector: Injector) {}

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    const formControl = this.injector.get(NgControl);
    try {

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }

    if(formControl.name === 'birthDate') {
      this.control.addValidators(dateValidator())
      this.control.updateValueAndValidity()
    }

    if(formControl.name === 'cpf' || formControl.name?.toString().toLowerCase() === 'cpfcontrol') {
      this.control.addValidators(cpfValidator())
      this.control.updateValueAndValidity()
    }

    if(formControl.name === 'expirationDateControl' || formControl.name === 'cardExpirationDate') {
      this.control.addValidators(expirationDateValidator())
      this.control.updateValueAndValidity()
    }

  }

  writeValue(value: T): void {
    // console.log('writeValue', value);
    
    // this.control
    //   ? this.control.setValue(value , { emitEvent: false,  })
    //   : (this.control = new FormControl(value));
  }

  registerOnChange(fn: (val: T | null) => T): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap((val) => fn(val))
      )
      .subscribe(() => this.control?.markAsUntouched());
  }

  registerOnTouched(fn: () => T): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateValue = control.value;

    if(dateValue !== null) {
      // Separa dia, mês e ano
      const [day, month, year] = dateValue.split('/').map(Number);
      
      // Verifica se o mês é válido
      if (month < 1 || month > 12) {
        return { invalidMonth: { value: month } };
      }
      
      // Verifica se o dia é válido para o mês
      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      const daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      if (day < 1 || day > daysInMonth[month - 1]) {
        return { invalidDay: { value: day } };
      }
      
      // Verifica se a data não é maior que a data atual
      const inputDate = new Date(year, month - 1, day);
      const currentDate = new Date();
      if (inputDate > currentDate) {
        return { futureDate: { value: dateValue } };
      }
      
      // Verifica se a data está no formato dd/mm/yyyy
      const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/;
      if (!datePattern.test(dateValue)) {
        return { invalidDate: { value: dateValue } };
      }
    }
      return null; // Se todas as validações passaram
  };
}



export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const cpf = control.value;    
    if (cpf === null || cpf === '') {
      return null;
    }
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return { invalidCpf: { value: cpf } }; // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    }
  
    let soma = 0;
    let resto;
  
    // Valida o primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return { invalidCpf: { value: cpf } };
    }
  
    soma = 0;
  
    // Valida o segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return { invalidCpf: { value: cpf } };
    }
  
    return null;
  };
}

export function expirationDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Se o campo estiver vazio, não há erro
    }

    // Verifica se o formato é MM/YY
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(value)) {
      return { invalidDate: true }; // Formato inválido
    }

    const [month, year] = value.split('/').map(Number);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Os meses começam em 0
    const currentYear = currentDate.getFullYear() % 100; // Pega os últimos dois dígitos do ano

    // Verifica se o ano e o mês são válidos
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return { expiredDate: true }; // Data de expiração inválida
    }

    return null; // Data válida
  };
}

