import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { Observable, Subject, map } from 'rxjs';
import { BaseService } from './base/base.service';
import { Notification } from 'src/app/shared/models/notificationsModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseService {

  private env = environment;

  private updateNotificationSubject$ = new Subject<Notification[]>();
  updateNotificationSubject = this.updateNotificationSubject$.asObservable();

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getAllNotifications(cpf: string, memberId: string): Observable<Notification[]> {
    return this.get<Notification[]>(`${this.env.publicApiSocioUrl}v1/socio/notifications?cpf=${cpf}&memberId=${memberId}`)
  }

  getNotificationById(id: string, memberId: string): Observable<Notification> {
    return this.get<Notification>(`${this.env.publicApiSocioUrl}v1/socio/notifications/${id}?memberId=${memberId}`);
  }

  deleteNotification(id: string, memberId: string): Observable<boolean> {
    return this.patch<boolean>(`${this.env.publicApiSocioUrl}v1/socio/notifications/${id}/${memberId}/deleted`);
  }

  setNotificationViwed(id: string, memberId: string): Observable<boolean> {
    return this.patch<boolean>(`${this.env.publicApiSocioUrl}v1/socio/notifications/${id}/${memberId}/viewed`);
  }

  setUpdateNotificationSubject(value: Notification[]) {
    this.updateNotificationSubject$.next(value);
  }
}
