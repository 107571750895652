<div class="card upgrade-card" id="upgrade-card">
    <div class="card-body d-grid py-2">
        <div class="row align-items-center">
            <div class="col-12 col-md-9">
                <div class="title mb-1">Faça upgrade do plano e receba mais benefícios</div>
            </div>
            <div class="col-12 col-md-3 btn-upgrade">
                <app-button id="btn-upgrade" class="align-self-end" [buttonOptions]="btnUpgradeOptions" (buttonClick)="btnUpgradeClick()"></app-button>
            </div>
        </div>
    </div>
</div>
