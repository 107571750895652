<div
  class="pt-4 pb-3 pt-md-0 px-md-3 px-lg-0"
  id="dashboard-page"
  *ngIf="showDashboard"
>
  <div class="hello-user my-3">
    <label for="">Olá {{ user.name.split(" ")[0] }}!</label>
  </div>
  <div class="row g-3">
    @if(plan){
    <!-- Visivel apenas no mobile -->

    @if(this.memberShipCard != null){
    <div class="d-md-none col-12">
      <div class="my-2 d-grid carteirinha justify-content-center">
        <!-- <img class="img-fluid" [src]="carteirinhaImg" alt="Carteitinha"> -->
        <div class="memberShipCard w-auto" [ngStyle]="styles">
          <div class="row m-0">
            <div class="col-9 d-flex align-items-start flex-column mb-2">
              <div class="mb-auto p-2 d-flex">
                <img
                  class="img-fluid imgCarteirinha mt-1"
                  [src]="carteirinhaImg"
                  alt="Carteitinha"
                />
                <h1 class="align-items-center mt-4" style="font-size: 1rem">
                  N°: {{ memberShipCard.cardNumber }}
                </h1>
              </div>
              <div class="p-2">
                <h1 class="align-items-end" style="font-size: 1.2rem">
                  {{ memberShipCard.member.name }}
                </h1>
              </div>
            </div>
            <div class="col-3 card-lateral"></div>
          </div>
        </div>
        @if(this.user.memberSituation?.id == memberSituationEnum.Adimplente &&
        this.memberShipCard != null && this.virtualCard == 'true'){
        <div
          id="show-carteirinha"
          class="p-2 mt-3 text-center show-carteirinha cursor-pointer"
          (click)="showCarteirinha()"
        >
          QrCode da Carteirinha <i class="bi bi-box-arrow-up-right"></i>
        </div>
        }
      </div>
    </div>
    }
    <div class="col-12 col-xl-8">
      <app-my-plan-card
        [invoiceList]="invoiceList"
        [plan]="plan"
      ></app-my-plan-card>
    </div>

    }

    <!-- Card Checkin -->

    <div class="col-12 col-md-6 col-xl-4" [ngStyle]="styles">
      <div class="card checkin-card">
        <div class="card-body d-grid">
          <div *ngIf="ingressosCheckIn.length === 0" class="card-title">
            Check-in
          </div>
          <div
            *ngIf="ingressosCheckIn.length > 0; else noGames"
            id="matchCarouselCheckin"
            class="carousel slide m-0 h-100"
            data-bs-ride="carousel"
          >
            <!-- Indicadores do carousel -->
            <ol class="carousel-indicators" *ngIf="showIndicators">
              <li
                *ngFor="let game of ingressosCheckIn; let i = index"
                [class.active]="i === 0"
                data-bs-target="#matchCarouselCheckin"
                [attr.data-bs-slide-to]="i"
              ></li>
            </ol>

            <!-- Slides do carousel -->
            <div class="carousel-inner h-100">
              <div
                class="carousel-item h-100 text-center"
                *ngFor="let game of ingressosCheckIn; let i = index"
                [class.active]="i === 0"
              >
                <div
                  class="h-100 d-flex flex-column justify-content-between align-items-center"
                >
                  <div>
                    <div class="match-date mb-3">
                      <span
                        >{{
                          commonService.getInvertedDate(game.waveGame.gameDate)
                        }}
                        às {{ game.waveGame.gameTime }}</span
                      >
                    </div>
                    <div class="match-location mb-3">
                      <p>{{ game.waveGame.championship.title }}</p>
                      <p class="d-none d-md-flex mx-2">/</p>
                      <p>{{ game.waveGame.location.name }}</p>
                    </div>
                  </div>
                  <div class="match d-flex justify-content-center">
                    <div class="row">
                      <div
                        class="col-5 d-flex justify-content-center align-items-center"
                      >
                        <div class="team px-2 px-sm-3">
                          <img [src]="teamLogo" alt="team 1" />
                          <div class="club-name">
                            <span>{{ game.waveGame.soccerTeamName }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-2 d-flex justify-content-center align-items-center"
                      >
                        <div class="versus mx-1 align-content-center">
                          <span>X</span>
                        </div>
                      </div>
                      <div
                        class="col-5 d-flex justify-content-center align-items-center"
                      >
                        <div class="team px-2 px-sm-3">
                          <img [src]="getAdversaryLogo(game)" alt="team 2" />
                          <div class="club-name">
                            <span>{{ game.waveGame.adversary.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <app-button
                      *ngIf="isActiveCheckins"
                      [style]="'--bs-btn-padding-x: 1.4em;'"
                      [buttonOptions]="checkinButtonOptions"
                      class="app-button"
                      (buttonClick)="openCheckInModal(game.id)"
                    ></app-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Controles do carousel -->
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#matchCarouselCheckin"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Anterior</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#matchCarouselCheckin"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Próximo</span>
            </button>
          </div>
          <ng-template #noGames>
            <div class="d-flex flex-column align-items-center h-100">
              <div class="text-center py-1 mt-3">
                <b>Sem jogos para realizar check-in</b>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <app-form-modal
      [modalId]="'modalCheckinTicket'"
      [modalTitle]="'Realizar Check-in'"
      [showCloseBtn]="false"
      [ticketData]="ingressosCheckIn"
      [checkinsHistory]="checkinsHistory"
      [limitCheckin]="limitCheckin"
      [checkinSelected]="checkinSelected"
      [selectInputOptions]="selectInputOptionsModal"
      [selectControl]="selectControlModal"
      (onModalConfirm)="onModalCheckinConfirm($event)"
    ></app-form-modal>

    <!-- Card Faturas-->
    <div class="col-12 col-xl-8">
      <app-invoice-table
        [memberPlanId]="memberPlanId"
        [invoiceList]="invoiceList"
        (invoiceTableResponse)="getMemberPlanDashboardData()"
      ></app-invoice-table>
    </div>

    <!-- Card Última Fatura -->
    <div class="col-12 col-md-6 col-xl-4">
      <div #invoiceCard id="invoiceCard" class="card last-invoice-card">
        <div class="card-body row user-info">
          <div class="card-title">Última Fatura</div>
          <div class="py-1 col-12 col-md-12">
            <b>Vencimento:</b>
            {{ lastInvoice?.dueDate | date : "dd/MM/yyyy" : "+0000" }}
          </div>
          <div class="py-1 col-12 col-md-12">
            <b>Referência:</b>
            {{
              lastInvoice?.refMonth
                ? lastInvoice?.refMonth + "/" + lastInvoice?.refYear
                : "-"
            }}
          </div>
          <div class="py-1 col-12 col-md-12">
            <b>Status:</b>
            {{ lastInvoice?.invoicePaymentStatus?.statusTypeName }}
          </div>
          <div class="py-1 col-12 col-md-12">
            <b>Tipo:</b> {{ lastInvoice?.invoiceType?.name }}
          </div>

          <div class="col-6 col-md-12 mt-2">
            <div class="invoice-value text-md-center">
              {{
                lastInvoice?.amount > 0
                  ? (lastInvoice?.amount | customCurrency)
                  : "R$ 0,00"
              }}
            </div>
          </div>

          <div class="d-flex d-md-grid flex-row-reverse">
            <!-- <div *ngIf="lastInvoice?.invoicePaymentStatus?.statusTypeName != 'Pago'" class="col-6 col-md-12"> -->
            <div *ngIf="showOptionsPayment()" class="col-6 col-md-12">
              <div class="payment-options text-center text-md-center py-1">
                Pagar fatura com
              </div>
              <div class="row payment-options-icons text-center p-1">
                <div
                  class="col-6 d-grid p-0 cursor-pointer"
                  (click)="openModal(PaymentType.CREDITO)"
                >
                  <i class="bi bi-credit-card m-1"></i>
                  <span>Crédito</span>
                </div>

                <div
                  class="col-6 d-grid justify-content-center p-0 cursor-pointer"
                  (click)="openModal(PaymentType.PIX)"
                >
                  <mat-icon
                    class="pix-mat-icon m-1"
                    svgIcon="pix-green"
                  ></mat-icon>
                  <span>PIX</span>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-md-12 mt-4 text-md-center align-content-center"
              [ngClass]="
                lastInvoice?.invoicePaymentStatus?.statusTypeName == 'Pago'
                  ? 'col-12 mt-2'
                  : ''
              "
            >
              <app-button
                [buttonOptions]="btnInvoiceDetailsOptions"
                (buttonClick)="goToInvoiceDetail(lastInvoice?.id)"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card Dados de Sócio-->
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card partner-card">
        <div class="card-body row user-info">
          <div class="card-title">Dados de Sócio</div>

          <div
            class="custom-scrollbar"
            style="max-height: 15rem; overflow-y: auto"
          >
            <div class="col-12 pb-3">
              <i class="bi bi-person-fill"></i><b> Nome:</b> {{ pattern?.name }}
            </div>
            <div class="col-12 pb-3" *ngIf="!secondaryPlanId">
              <i class="bi bi-exclamation-circle-fill"></i><b> Situação:</b>
              {{ pattern?.memberSituation?.name }}
            </div>
            <div class="col-12 pb-3">
              <i class="bi bi-calendar-fill"></i><b> Data de Associação:</b>
              {{ pattern?.associationDate | date : "dd/MM/yyyy" : "+0000" }}
            </div>
            <div
              class="col-12 pb-3"
              *ngIf="showContinuousMonth && pattern?.continuousMonthsCount > 0"
            >
              <i class="bi bi-calendar-check"></i><b> Meses Ininterruptos:</b>
              {{
                pattern.continuousMonthsCount > 1
                  ? pattern.continuousMonthsCount + " meses"
                  : pattern.continuousMonthsCount + " mês"
              }}
            </div>
            <div class="col-12 pb-3">
              <i class="bi bi-envelope-fill"></i><b> E-mail:</b>
              {{ pattern?.emails[0]?.email }}
            </div>
            <div class="col-12 pb-3">
              <i class="bi bi-telephone-fill"></i><b> Telefone:</b>
              {{ pattern?.phones[0]?.number | phone }}
            </div>
            <div class="col-12 pb-3">
              <i class="bi bi-gender-ambiguous"></i><b> Gênero:</b>
              {{
                pattern?.gender === "M"
                  ? "Masculino"
                  : pattern?.gender === "F"
                  ? "Feminino"
                  : "Não informado"
              }}
            </div>
            <div class="col-12 pb-3 d-none d-md-block">
              <i class="bi bi-house-door-fill"></i><b> Endereço:</b>
              {{ this.getAddress() }}
            </div>
          </div>

          <app-button
            class="align-content-center"
            [buttonOptions]="btnUpdateDataOptions"
            (buttonClick)="goToPartnerData()"
          ></app-button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md">
      <div class="card experience-card">
        <div class="card-body d-grid">
          <div class="card-title">Experiências</div>
          <ng-container
            *ngIf="
              experiences && experiences.length > 0 && isActiveExperiences;
              else noExperiences
            "
          >
            <div class="row align-content-between">
              <div
                *ngFor="let item of experiences; trackBy: trackByIndex"
                class="col-12 col-md-12 py-1 d-flex"
              >
                <div class="col-2 col-xxl-1">
                  <img
                    class="icon"
                    style="height: 23px; width: 23px"
                    [src]="item.src"
                    [alt]="'Imagem ' + item.name"
                  />
                </div>
                <div
                  *ngIf="item.description"
                  class="col-6 col-xxl-7 ex-info px-1 user-info"
                >
                  {{ item.description }}
                </div>
                <div
                  *ngIf="item.requiredPoints"
                  class="col-4 points px-0 pe-2 text-end user-info"
                >
                  <b>{{ item.requiredPoints }} pontos</b>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noExperiences>
            <div class="row align-content-center">
              <div class="col-12 text-center py-1">
                <b>Não existem itens a serem exibidos </b>
              </div>
            </div>
          </ng-template>

          <app-button
            *ngIf="experiences && experiences.length > 0 && isActiveExperiences"
            class="align-content-end mt-4"
            [buttonOptions]="btnExperienceOptions"
            (buttonClick)="goToExperiences()"
          ></app-button>

          <!-- <app-button
            *ngIf="
              !experiences || experiences.length <= 0 || !isActiveExperiences
            "
            class="align-content-end mt-4"
            [buttonOptions]="btnGuessesOptions"
            (buttonClick)="goToExperiences()"
          ></app-button> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-md">
      <div class="card partnership-card">
        <div class="card-body d-grid">
          <div class="card-title">Parcerias e Cupons</div>

          <div class="row align-content-between">
            <ng-container *ngIf="isBenefitsActive; else noBenefits">
              <ng-container
                *ngIf="partners && partners.length > 0; else noPartners"
              >
                <div
                  *ngFor="let item of partners; trackBy: trackByIndex"
                  class="col-12 col-md-12 py-1 d-flex"
                >
                  <div class="col-2 col-xxl-1">
                    <img
                      class="icon"
                      style="height: 23px; width: 23px"
                      [src]="item.src"
                      [alt]="'Imagem ' + item.partnerName"
                    />
                  </div>
                  <div
                    *ngIf="item.description"
                    class="col-10 align-content-center col-xxl-11 partnership-info px-1 user-info"
                  >
                    {{ item.description }}
                  </div>
                </div>

                <app-button
                  class="align-content-end mt-4"
                  [buttonOptions]="btnPartnershipsCouponsOptions"
                  (buttonClick)="showAllCoupons()"
                ></app-button>
              </ng-container>
            </ng-container>
            <ng-template #noBenefits>
              <div class="col-12 text-center py-1">
                <b>Sem Dados para exibição</b>
              </div>
            </ng-template>
            <ng-template #noPartners>
              <div class="col-12 text-center py-1">
                <b>Parcerias em breve</b>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
@if(paymentType){
<app-payment-invoice-modal
  #paymentModal
  [modalId]="modalId"
  [paymentType]="paymentType"
  [paymentInvoiceModalData]="paymentInvoiceModalData"
  (confirmActionChange)="onModalConfirm($event)"
></app-payment-invoice-modal>
}

<app-modal
  [modalTitle]="'Termos de Uso'"
  modalId="termsModal"
  [size]="'modal-xl'"
  [showCloseBtn]="false"
  [scrollable]="true"
  [modalBodyMessage]="termsModalBodyMessage"
  modalConfirmLabel="Aceitar e Continuar"
  [backdropType]="'static'"
  (confirmActionChange)="onTermsModalConfirm()"
></app-modal>
