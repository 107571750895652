import { Component, ViewChild, ElementRef, input } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { MemberPlan } from '../../models/userModel';
import { CommonModule } from '@angular/common';
import { PlanService } from 'src/app/core/services/plan.service';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';

declare var bootstrap: any;

@Component({
  selector: 'app-future-plans-modal',
  standalone: true,
  imports: [AppButtonComponent, CommonModule, CustomCurrencyPipe],
  templateUrl: './future-plans-modal.component.html',
  styleUrl: './future-plans-modal.component.scss',
})
export class FuturePlansModalComponent {
  @ViewChild('futurePlansModal') futurePlansModal!: ElementRef;

  memberId = input.required<string>();
  futurePlans: any;

  btnFuturePlansOptions = {
    buttonText: 'Planos futuros',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  constructor(private planService: PlanService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  openModal() {
    this.getFuturePlansDetail();
    const modalElement = this.futurePlansModal.nativeElement;
    const modalInstance = new bootstrap.Modal(modalElement, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    });
    modalInstance.show();
  }

  closeModal() {
    const modalElement = this.futurePlansModal.nativeElement;
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  getFuturePlansDetail() {
    this.planService.getFuturePlansDetail(this.memberId()).subscribe({
      next: (res: any) => {
        this.futurePlans = res;
      },
      error: (error: any) => {},
    });
  }
}
