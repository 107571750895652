import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, computed, Input, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/shared/models/notificationsModel';
import { SupportRouterService } from 'src/app/shared/services/support-router.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';


declare var bootstrap: any;

@Component({
  selector: 'notification-dropdown',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './notification-dropdown.component.html',
  styleUrl: './notification-dropdown.component.scss'
})
export class NotificationDropdownComponent {
  styles = input<any>();

  notifications:any = [];
  @Input() memberId:any;
  @Input() memberCpf:any;
  notificationsLoaded: boolean = false;
  isDropdownOpen: boolean = false;
  markAllNotificationsAsRead = output();

  maxNotifications = 3;


  notificationsBadge = computed<number>(() => {
    if (!this.notifications?.length) {
      return 0;
    }

    return this.notifications.filter((notification:any) => notification.isViewed === false).length;
  });

  constructor(private router: Router, private cdr: ChangeDetectorRef, private supportRouterService:SupportRouterService, private notificationService: NotificationsService) { }

  markAllAsRead() {
    this.markAllNotificationsAsRead.emit();
  }


  loadAllNotification() {
    if (this.isDropdownOpen) {
      this.toggleDropdown(); // Se já está aberto, só alterna o estado
      return;
    } else {
      this.notificationService.getAllNotifications(this.memberCpf, this?.memberId).subscribe(notifications => {
        this.notifications = notifications;


        this.toggleDropdown();
      });
    }
  }

  toggleDropdown() {
    const dropdownElement = document.querySelector('.dropdown-menu');
    if (dropdownElement) {
      const dropdown = new bootstrap.Dropdown(dropdownElement);

      if (this.isDropdownOpen) {
        dropdown.hide();
      } else {
        dropdown.show();
      }

      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }

  viewNotification(notification: Notification) {
    if(!this.router.url.includes('/lista-notificacoes')) {
      this.supportRouterService.setRota(this.router.url)
    }
    this.router.navigate([`/lista-notificacoes/${notification.id}`]);
  }

  viewAllNotifications() {
    // Go to notifications page
    this.router.navigate(['/lista-notificacoes']);
  }

}
